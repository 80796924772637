<template>
  <div class="">
    <div class="">
      <AreYouSureModal
        :showModal="checkDeleteModal"
        :message="'Are you sure you want to delete your Slydepost?'"
        @accept="this.deleteSlydepost()"
        @cancel="checkDeleteModal = false"
      />
      <AreYouSureModal
        :showModal="checkReportModal"
        :message="'Are you sure you want to report this Slydepost?'"
        @accept="this.reportSlydepost()"
        @cancel="checkReportModal = false"
      />
      <div v-if="isPreview === false" class="flex flex-row items-center px-2 md:px-4 py-2 bg-white h-20">
        <div class="pr-4">
          <img v-lazy="this.getProfilePicture" alt="Avatar" class="rounded-2xl w-12 h-12" />
        </div>
        <div>
          <router-link :to="`/profile/${this.userId}`">
            <div class="flex flex-row gap-1">
              <p class="font-satoshi font-bold text-blue capitalize">
                {{ this.userFirstName }}
              </p>
              <p class="font-satoshi font-bold text-blue capitalize">
                {{ this.userLastName }}
              </p>
            </div>
          </router-link>
          <p class="text-left text-sm">{{ this.formattedCreatedAtDate }}</p>
        </div>
        <div class="flex-1"></div>

        <div
          class="flex items-start h-full z-20 relative cursor-pointer bg-transparent"
          @click="this.showSlydepostOptions = !this.showSlydepostOptions"
          v-click-outside="this.closeSlydepostOptions"
        >
          <font-awesome-icon class="" icon="fa-solid fa-ellipsis" />
          <DropdownEmitContent
            v-if="this.postBelongsToUser()"
            class="absolute -bottom-6 bg-transparent z-20"
            :active="this.showSlydepostOptions"
            :options="postOwnerOptions"
            @editSlydepost="this.editSlydepost()"
            @deleteSlydepost="this.checkDelete()"
          />
          <DropdownEmitContent
            v-else
            class="absolute bottom-1 bg-transparent z-20"
            :active="this.showSlydepostOptions"
            :options="postNotOwnedByUserOptions"
            @reportSlydepost="this.checkReport()"
          />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end -mt-8 md:-mt-12">
      <MetaRating :meta-rating="this.metaRating" />
    </div>
    <div class="-mt-9 md:-mt-10">
      <MediaCarousel :is-preview="isPreview" :files='files' :media="this.media"/>
    </div>
    <div class="px-4 md:px-6 py-4 bg-white">
      <SlydepostBody
        :category="this.category"
        :subcategory="this.subcategory"
        :description="this.description"
        :address="this.address"
        :adjectives="this.adjectives"
        :isPreview="false"
        :likes="this.likes"
        :title="this.title"
        :link="this.link"
        :id="this.id"
        :user-id="this.userId"
        :has-aspect-descriptions="this.adjectiveDescriptionsExist()"
        @likeSlydepost="likeSlydepost"
        @deleteLikeSlydepost="deleteLikeSlydepost"
        @showAddToListModal="showAddToListModal"
      />
    </div>
    <hr class="border-gray border-b" />
    <div class="px-4 md:px-6 py-2 bg-white">
      <SlydepostComments
        :comments="this.comments"
        :display="this.displayComments"
        :postId="this.id"
        :post-owner-id="this.userId"
        :post-title="this.title"
        @deleteComment="deleteComment"
        @createComment="createComment"
        @likeComment="likeComment"
        @deleteCommentLike="deleteCommentLike"
        @replyToComment="replyToComment"
        @deleteReply="deleteReply"
        @replyToReply="replyToReply"
        @likeReply="likeReply"
        @deleteLikeReply="deleteLikeReply"
      />
    </div>
    <AddToListModel
      v-if="showAddToList"
      :is-create-post="isCreatePost"
      :post-id="this.id"
      :post-owner-id="this.userId"
      @closeModal="this.showAddToList = false"
    />
  </div>
</template>

<script>
import MediaCarousel from './MediaCarousel.vue';
import SlydepostComments from './SlydepostComments.vue';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
import DropdownEmitContent from '@/components/DropdownMenu/DropdownEmitContent.vue';
import MetaRating from '@/components/ViewSlydepost/MetaRating.vue';
import SlydepostBody from './SlydepostBody.vue';
import router from '@/router';
import store from '@/store/store';
import { uuid } from 'vue-uuid';
import client from '@/axios/client';
import AddToListModel from '@/components/Modals/AddToListModel.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Slydepost',
  components: {
    MediaCarousel,
    SlydepostComments,
    AreYouSureModal,
    SlydepostBody,
    DropdownEmitContent,
    MetaRating,
    AddToListModel,
  },
  props: {
    id: {},
    category: {},
    subcategory: {},
    title: {},
    address: {},
    link: {},
    description: {},
    media: {},
    files: Array,
    adjectives: {},
    comments: {},
    userId: {},
    metaRating: {},
    displayComments: {},
    createdAt: {},
    userFirstName: {},
    userLastName: {},
    profilePictureUrl: {},
    isPreview: {
      type: Boolean,
      default: false,
    },
    likes: {
      type: Array,
    },
    isDraft: Boolean,
    isCreatePost: Boolean,
  },
  emits: [
    'deleteComment',
    'createComment',
    'likeComment',
    'deleteCommentLike',
    'replyToComment',
    'deleteReply',
    'replyToReply',
    'likeReply',
    'deleteLikeReply',
    'likeSlydepost',
    'deleteLikeSlydepost',
    'deleteSlydepost',
  ],
  data() {
    return {
      // formattedCreatedAtDate: '',
      showAddToList: false,
      showSlydepostOptions: false,
      checkDeleteModal: false,
      checkReportModal: false,
      showEditModal: false,
      postOwnerOptions: [
        {
          text: 'Edit',
          onClickEmit: 'editSlydepost',
        },
        {
          text: 'Delete',
          onClickEmit: 'deleteSlydepost',
        },
      ],
      postNotOwnedByUserOptions: [
        {
          text: 'Report',
          onClickEmit: 'reportSlydepost',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAddToListModalPayload: 'listsStoreModule/getAddToListModalPayload',
    }),
    formattedCreatedAtDate() {
      if (this.createdAt == null) {
        return new Date().toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      } else {
        return new Date(this.createdAt).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      }
    },
    getProfilePicture() {
      if (this.profilePictureUrl != null || this.profilePictureUrl === '') {
        return this.profilePictureUrl;
      } else {
        // s3 url of default avatar
        return 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
      }
    },
    loggedInUserId: {
      get() {
        return this.$store.getters['authStoreModule/getUserId'];
      },
    },
  },
  methods: {
    showAddToListModal() {
      this.showAddToList = true;
    },
    deleteLikeSlydepost(payload) {
      this.$emit('deleteLikeSlydepost', payload);
    },
    likeSlydepost(payload) {
      this.$emit('likeSlydepost', payload);
    },
    deleteLikeReply(payload) {
      this.$emit('deleteLikeReply', payload);
    },
    likeReply(payload) {
      this.$emit('likeReply', payload);
    },
    replyToReply(payload) {
      this.$emit('replyToReply', payload);
    },
    deleteReply(payload) {
      this.$emit('deleteReply', payload);
    },
    replyToComment(payload) {
      this.$emit('replyToComment', payload);
    },
    deleteCommentLike(payload) {
      this.$emit('deleteCommentLike', payload);
    },
    likeComment(payload) {
      this.$emit('likeComment', payload);
    },
    deleteComment(payload) {
      this.$emit('deleteComment', payload);
    },
    createComment(payload) {
      this.$emit('createComment', payload);
    },
    closeSlydepostOptions() {
      this.showSlydepostOptions = false;
    },
    adjectiveDescriptionsExist() {
      if (this.adjectives == null) {
        return false;
      }
      for (let i = 0; i < this.adjectives.length; i++) {
        if (this.adjectives[i].description != null && this.adjectives[i].description !== '') {
          return true;
        }
      }
      return false;
    },
    toggleSlydepostMenu() {
      this.showSlydepostOptions = !this.showSlydepostOptions;
    },
    postBelongsToUser() {
      const loggedInUserId = this.loggedInUserId;
      return loggedInUserId === this.userId;
    },
    displaySlydepostOptions() {
      const loggedInUserId = this.loggedInUserId;
      if (loggedInUserId === this.userId) {
        return [
          {
            itemName: 'Edit',
            link: '/',
          },
          {
            itemName: 'Delete',
            link: '/',
          },
        ];
      } else {
        return [];
      }
    },
    checkDelete() {
      this.showSlydepostOptions = false;
      this.checkDeleteModal = true;
    },
    checkReport() {
      this.showSlydepostOptions = false;
      this.checkReportModal = true;
    },
    deleteSlydepost() {
      let payload = {
        userId: this.loggedInUserId,
        postId: this.id,
      };
      this.$store.dispatch('deleteSlydepost', payload);
      this.checkDeleteModal = false;
      this.$emit('deleteSlydepost', this.id);
    },
    editSlydepost() {
      if (this.id == null) {
        return;
      }
      store.commit('editPostStoreModule/setId', this.id);
      store.commit('editPostStoreModule/setTitle', this.title);
      store.commit('editPostStoreModule/setCategory', this.category);
      store.commit('editPostStoreModule/setSubcategory', this.subcategory);
      if (this.subcategory != null) {
        store.commit('editPostStoreModule/setCategoryDropdownSearchFilter', this.subcategory);
      } else {
        store.commit('editPostStoreModule/setCategoryDropdownSearchFilter', this.category);
      }
      store.commit('editPostStoreModule/setAddress', this.address);
      store.commit('editPostStoreModule/setLink', this.link);
      store.commit('editPostStoreModule/setDescription', this.description);

      let aspects = [];
      for (let i = 0; i < this.adjectives.length; i++) {
        let aspect = {
          name: this.adjectives[i].name,
          rating: this.adjectives[i].rating,
          description: this.adjectives[i].description,
          selected: true,
        };
        aspects.push(aspect);
      }
      store.commit('editPostStoreModule/setAspects', aspects);

      if (this.media != null) {
        let existingImages = [];
        for (let i = 0; i < this.media.length; i++) {
          if(this.media.type === 'IMAGE'){
            existingImages.push({
              file: this.media[i].presignedUrl,
              order: this.media[i].order,
              type: this.media[i].type,
            });
          } else {
            existingImages.push({
              file: this.media[i].videoThumbnail,
              order: this.media[i].order,
              type: this.media[i].type
            })
          }
        }
        store.commit('editPostStoreModule/setExistingS3Images', existingImages);
      }
      store.commit('editPostStoreModule/setExistingMedia', this.media);
      router.push('/slydepost/edit');
    },
    reportSlydepost() {
      this.checkReportModal = false;
      const userId = this.loggedInUserId;
      const postId = this.id;
      const ownerUserId = this.userId;
      const jsonBody = JSON.stringify({
        postId,
        ownerUserId,
        userId,
      });
      client
        .post(`/hubspot/ticket/`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          gtag('event', 'slydepost-reported', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((res) => {
          console.error(res);
        });
    },
  },
  mounted() {},
};
</script>
