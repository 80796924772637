<template>
  <div>
    <video ref="videoPlayer" controls></video>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    source: {},
  },
  data() {
    return {};
  },
  watch: {
    source() {
      this.initializeOrUpdateSource();
    },
  },
  mounted() {
    this.initializeOrUpdateSource();
  },
  methods: {
    initializeOrUpdateSource() {
      const video = this.$refs.videoPlayer;

      // video source is coming from a local file
      if(this.source.blobUrl != null && this.source.blobUrl !== ''){
        console.log(`using blob url for video source`)
        video.src = this.source.blobUrl
      }
      // video player supports apple video, use hls format
      else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        console.log('using hls video')
        video.src = this.source.hlsVideoUrl;
      }
      // use mp4 format as default
      else {
        console.log(`using mp4 video`)
        video.src = this.source.mp4VideoUrl;
      }
    }
  }
}
</script>