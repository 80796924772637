import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const insiderCreateProductStoreModule = {
  namespaced: true,
  state: {
    productType: '',
    priceType: '',
    price: 0,
    isFreeTrial: false,
    freeTrialDays: 0,
    selectedLists: [],
    createProductStatus: 'waiting', // waiting, inProgress, done, error
  },
  getters: {
    getSelectedLists(state){
      return state.selectedLists;
    },
    getProductType(state){
      return state.productType
    },
    getPriceType(state){
      return state.priceType
    },
    getPrice(state){
      return state.price
    },
    getIsFreeTrial(state){
      return state.isFreeTrial
    },
    getFreeTrialDays(state){
      return state.freeTrialDays
    },
    getCreateProductStatus(state){
      return state.createProductStatus
    },
  },
  mutations: {
    setSelectedLists(state, lists){
      state.selectedLists = lists
    },
    setProductType(state, type){
      state.productType = type
    },
    setPriceType(state, type){
      state.priceType = type;
    },
    setPrice(state, price){
      state.price = price;
    },
    setIsFreeTrial(state, trial){
      state.isFreeTrial = trial;
    },
    setFreeTrialDays(state, days){
      state.freeTrialDays = days;
    },
    setCreateProductStatus(state, status){
      state.createProductStatus = status
    },
    clear(state){
      state.productType = '';
      state.priceType = '';
      state.price = 0;
      state.isFreeTrial = false;
      state.freeTrialDays = 0;
      state.createProductStatus = 'waiting';
    }
  },
  actions: {
    createProduct({ commit, getters, rootGetters }){
      if(getters.getCreateProductStatus === 'inProgress'){
        return;
      }
      commit('setCreateProductStatus', 'inProgress')

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const price = parseFloat(getters.getPrice);
      const isFreeTrial = getters.getIsFreeTrial;
      const freeTrialDays = getters.getFreeTrialDays;
      const productType = getters.getProductType;
      const priceType = getters.getPriceType;
      const lists = getters.getSelectedLists;
      const contentOwnerProfilePicture = rootGetters['getS3Url']
      const jsonBody = JSON.stringify({
        userId: loggedInUserId,
        price,
        isFreeTrial,
        freeTrialDays,
        productType,
        priceType,
        lists,
        contentOwnerProfilePicture
      });

      return client
        .post(`/user/insider/product`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          commit('setCreateProductStatus', 'done')
          gtag('event', 'insider-product-created', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((error) => {
          console.error(error);
          commit('setCreateProductStatus', 'error')
        });
    }
  },
};

// export the entire module
export { insiderCreateProductStoreModule };
