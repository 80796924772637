import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const insiderStoreModule = {
  namespaced: true,
  state: {
    products: [],
    upgradeStatus: 'waiting', // waiting, inProgress, done, error
    monthlySubscriptions: [],
    yearlySubscriptions: [],
    listPurchases: []
  },
  getters: {
    getListPurchases(state){
      return state.listPurchases;
    },
    getYearlySubscriptions(state){
      return state.yearlySubscriptions;
    },
    getMonthlySubscriptions(state){
      return state.monthlySubscriptions;
    },
    getProducts(state){
      return state.products;
    },
    getUpgradeStatus(state){
      return state.upgradeStatus;
    },
  },
  mutations: {
    setListPurchases(state, purchases){
      state.listPurchases = purchases;
    },
    setYearlySubscriptions(state, subscriptions){
      state.yearlySubscriptions = subscriptions;
    },
    setMonthlySubscriptions(state, subscriptions){
      state.monthlySubscriptions = subscriptions;
    },
    setProducts(state, products){
      state.products = products;
    },
    setUpgradeStatus(state, status){
      state.upgradeStatus = status;
    },
    clear(state){
      state.products = [];
      state.monthlySubscriptions = [];
      state.yearlySubscriptions = [];
      state.listPurchases = [];
      state.upgradeStatus = 'waiting';
    }
  },
  actions: {
    async getProducts({ dispatch, commit, getters, rootGetters }){
      await dispatch('getLoggedInUserProfile', null, { root: true });

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const insiderId = rootGetters['getInsiderId']
      console.log('insiderId', insiderId)
      client
        .get(`/user/insider/products/${insiderId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          commit('setProducts', response.data)
        })
        .catch((error) => {
          console.error(error);
          commit('setCreateProductStatus', 'error')
        });
    },
    upgrade({ commit, getters, rootGetters }){
      if(getters.getUpgradeStatus === 'inProgress'){
        return;
      }
      commit('setUpgradeStatus', 'inProgress')

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const jsonBody = JSON.stringify({
        userId: loggedInUserId,
      });

      return client
        .post(`/user/insider`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          commit('setUpgradeStatus', 'done');
          gtag('event', 'user-upgraded-to-insider', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
          return response;
        })
        .catch((error) => {
          console.error(error);
          commit('setUpgradeStatus', 'error');
          // if (error.response) {
          //   // Pass error status to the component for further handling
          //   return Promise.reject(error.response.status);
          // }
          return Promise.reject(error); // Handle non-response errors
        });
    },
    verifyCheckoutSession({ dispatch, commit, getters, rootGetters }, payload){
      const sessionId = payload.sessionId;
      const insiderId = payload.insiderId;
      const customerId = payload.customerId;
      const contentId = payload.contentId;
      const contentType = payload.contentType;
      const jsonBody = JSON.stringify({
        sessionId,
        insiderId,
        customerId,
        contentId,
        contentType
      });
      client
        .post(`/user/insider/checkout/verify`, jsonBody, {
          withCredentials: true,
          headers: {
            'User-Id': rootGetters['authStoreModule/getUserId'],
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {
          let redirectUrl = response.data.link;
          window.location.href = redirectUrl;
        })
        .catch((error) => {
          console.error(`Failed to verify checkout session`, error);
        });
    },
    async getInsiderPayments({ dispatch, commit, getters, rootGetters }) {
      await dispatch('getLoggedInUserProfile', null, { root: true });

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const insiderId = rootGetters['getInsiderId']
      console.log('insiderId', insiderId)
      client
        .get(`/user/insider/income/${loggedInUserId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          commit('setMonthlySubscriptions', response.data.monthlySubscriptions);
          commit('setYearlySubscriptions', response.data.yearlySubscriptions);
          commit('setListPurchases', response.data.listPayments);
        })
        .catch((error) => {
          console.error(error);
          commit('setCreateProductStatus', 'error')
        });
    },
    confirmInsiderSignup({ dispatch, commit, getters, rootGetters }, payload) {
      const accountId = payload.accountId;
      const insiderId = payload.insiderId;
      const jsonBody = JSON.stringify({
        accountId,
        insiderId
      });
      client
        .post(`/user/insider/confirm`, jsonBody, {
          withCredentials: true,
          headers: {
            'User-Id': rootGetters['authStoreModule/getUserId'],
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {
          let redirectUrl = response.data.link;
          window.location.href = redirectUrl;
        })
        .catch((error) => {
          console.error(`Failed to verify insider signup`, error);
        });
    },
    goToStripeLogin({ dispatch, commit, getters, rootGetters }){
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      const jsonBody = JSON.stringify({});
      client
        .post(`/user/insider/stripe/login/${loggedInUserId}`, jsonBody,{
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          }
        })
        .then((response) => {
          let redirectUrl = response.data.link;
          window.location.href = redirectUrl;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
};

// export the entire module
export { insiderStoreModule };
