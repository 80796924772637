<template>
  <AreYouSureDraftModal
    :message="'Do you want to save your progress and update this draft?'"
    :show-modal="showDraftNavigateAwayModal"
    @accept="updateDraftAndNavigateAway"
    @cancel="navigateAwayWithoutSaving"
    @close="closeModalWithoutAction"
  />
  <AreYouSureDraftModal
    :message="'Do you want to save your progress as a draft?'"
    :show-modal="showNavigateAwayModal"
    @accept="saveDraftAndNavigateAway"
    @cancel="navigateAwayWithoutSaving"
    @close="closeModalWithoutAction"
  />
  <CreateSlydepost :is-editing="false" @exit="this.exit()" @slydepostCreated="this.completedSlydepost = true" />
</template>

<script>
import CreateSlydepost from '@/components/CreateSlydepost/CreateSlydepost.vue';
import AreYouSureDraftModal from '@/components/Modals/AreYouSureDraftModal.vue';
import router from '@/router';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore.js';

export default {
  name: 'NewSlydepost',
  components: {
    CreateSlydepost,
    AreYouSureDraftModal,
  },
  data() {
    return {
      navigateToPath: '',
      isNavigateAway: false,
      showNavigateAwayModal: false,
      completedSlydepost: false,
      showDraftNavigateAwayModal: false,
    };
  },
  methods: {
    exit() {
      router.push('/');
    },
    async updateDraftAndNavigateAway() {
      const draftData = this.getCurrentSlydepostData();
      draftData.id = this.$store.getters['createPostStoreModule/getDraftId'];
      const draftStore = useSlydepostDraftStore();
      await draftStore.updateDraft(draftData);
      this.navigateAwayWithoutSaving();
    },
    navigateAwayWithoutSaving() {
      this.$router.replace(this.navigateToPath || '/');
    },
    closeModalWithoutAction() {
      this.showNavigateAwayModal = false;
      this.showDraftNavigateAwayModal = false;
    },
    async saveDraftAndNavigateAway() {
      this.showNavigateAwayModal = false;
      const draftStore = useSlydepostDraftStore();
      this.saveDataIntoDraft();
      let newDraft = await this.$store.dispatch('createPostDraftStoreModule/createDraft');
      if (newDraft !== undefined) {
        draftStore.slydepostDrafts.unshift(newDraft);
      }
      this.completedSlydepost = true
      this.navigateAwayWithoutSaving();
    },
    saveDataIntoDraft(){
      this.$store.commit('createPostDraftStoreModule/setTitle', this.$store.getters['createPostStoreModule/getTitle'])
      this.$store.commit('createPostDraftStoreModule/setAddress', this.$store.getters['createPostStoreModule/getAddress'])
      this.$store.commit('createPostDraftStoreModule/setLink', this.$store.getters['createPostStoreModule/getLink'])
      this.$store.commit('createPostDraftStoreModule/setCategory', this.$store.getters['createPostStoreModule/getCategory'])
      this.$store.commit('createPostDraftStoreModule/setSubcategory', this.$store.getters['createPostStoreModule/getSubcategory'])
      this.$store.commit('createPostDraftStoreModule/setFiles', this.$store.getters['createPostStoreModule/getFiles'])
      this.$store.commit('createPostDraftStoreModule/setExistingS3Images', this.$store.getters['createPostStoreModule/getExistingS3Images'])
      this.$store.commit('createPostDraftStoreModule/setAspects', this.$store.getters['createPostStoreModule/getSelectedAspects'])
      this.$store.commit('createPostDraftStoreModule/setDescription', this.$store.getters['createPostStoreModule/getDescription'])
      this.$store.commit('createPostDraftStoreModule/setSharedWith', this.$store.getters['createPostStoreModule/getSharedWith'])
    },
    getCurrentSlydepostData() {
      return {
        title: this.$store.getters['createPostStoreModule/getTitle'],
        address: this.$store.getters['createPostStoreModule/getAddress'],
        link: this.$store.getters['createPostStoreModule/getLink'],
        category: this.$store.getters['createPostStoreModule/getCategory'],
        subcategory: this.$store.getters['createPostStoreModule/getSubcategory'],
        files: this.$store.getters['createPostStoreModule/getFiles'],
        aspects: this.$store.getters['createPostStoreModule/getSelectedAspects'],
        description: this.$store.getters['createPostStoreModule/getDescription'],
        sharedWith: this.$store.getters['createPostStoreModule/getSharedWith'],
        metaRating: this.$store.getters['createPostStoreModule/getMetaRating'],
        userId: this.$store.getters['authStoreModule/getUserId'],
        userFirstName: this.$store.getters['authStoreModule/getFirstName'],
        userLastName: this.$store.getters['authStoreModule/getLastName'],
        suggestedAspects: this.$store.getters['createPostStoreModule/getSuggestedAspects'],
        categoryDropdownSelectedOptionIndex:
          this.$store.getters['createPostStoreModule/getCategoryDropdownSelectedOptionIndex'],
        username: this.$store.getters['authStoreModule/getUser'].username,
        userProfilePictureUrl: this.$store.getters['authStoreModule/getProfilePhoto'],
      };
    },
  },
  beforeRouteLeave(to, from) {
    if (this.completedSlydepost === true && this.showDraftNavigateAwayModal === false) {
      return true;
    } else if (this.showNavigateAwayModal === false && this.showDraftNavigateAwayModal === false) {
      this.navigateToPath = to.path;
      let title = this.$store.getters['createPostStoreModule/getTitle'];
      if (title === '' || title === null || title === undefined) {
        return true;
      }
      let draftId = this.$store.getters['createPostStoreModule/getDraftId'];
      if (draftId !== null && draftId !== '') {
        this.showDraftNavigateAwayModal = true;
        return false;
      }
      this.showNavigateAwayModal = true;
      return false;
    }
  },
};
</script>
