import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const editPostDraftStoreModule = {
  namespaced: true,
  state: {
    draftId: '',
    title: '',
    address: '',
    link: '',
    category: '',
    subcategory: '',
    categoryDropdownSearchFilter: '',
    categoryDropdownSelectedOptionIndex: -1,
    files: [],
    uploadProgress: {},
    isUploadingFilesToS3: false,
    aspects: [],
    existingS3Images: [],
    suggestedAspects: [],
    description: '',
    sharedWith: '',
    saveDraftStatus: 'waiting', // waiting, inProgress, done, error
  },
  getters: {
    getDraftId(state){
      return state.draftId
    },
    getIsUploadingFilesToS3(state){
      return state.isUploadingFilesToS3;
    },
    getUploadProgress(state){
      return state.uploadProgress
    },
    getSaveDraftStatus(state) {
      return state.saveDraftStatus;
    },
    getTitle(state) {
      return state.title;
    },
    getExistingS3Images(state) {
      return state.existingS3Images;
    },
    getAddress(state) {
      return state.address;
    },
    getLink(state) {
      return state.link;
    },
    getCategory(state) {
      return state.category;
    },
    getSubcategory(state) {
      return state.subcategory;
    },
    getCategoryDropdownSearchFilter(state) {
      return state.categoryDropdownSearchFilter;
    },
    getCategoryDropdownSelectedOptionIndex(state) {
      return state.categoryDropdownSelectedOptionIndex;
    },
    getFiles(state) {
      return state.files;
    },
    getAspects(state) {
      return state.aspects;
    },
    getSuggestedAspects(state) {
      return state.suggestedAspects;
    },
    getTotalAspectsSelected(state) {
      let selectedAspectLength = 0;
      for (let i = 0; i < state.aspects.length; i++) {
        if (state.aspects[i].selected === true) {
          selectedAspectLength += 1;
        }
      }
      return selectedAspectLength;
    },
    getSelectedAspects(state) {
      let selectedAspects = [];
      for (let i = 0; i < state.aspects.length; i++) {
        if (state.aspects[i].selected === true) {
          selectedAspects.push(state.aspects[i]);
        }
      }
      return selectedAspects;
    },
    getDescription(state) {
      return state.description;
    },
    getMetaRating(state) {
      if(state.aspects.length === 0){
        return 0;
      }
      let totalAspectRating = 0;
      let numberOfAspects = 0;
      state.aspects.forEach((aspect) => {
        if (aspect.selected === true) {
          totalAspectRating += parseInt(aspect.rating);
          numberOfAspects += 1;
        }
      });
      const finalRating = Math.round((totalAspectRating / numberOfAspects) * 10) / 10;
      return finalRating;
    },
    getImages(state) {
      let images = [];
      if (state.files.length > 0) {
        for (let i = 0; i < state.files.length; i++) {
          images.push({
            file: URL.createObjectURL(state.files[i].file),
            order: i,
            type: state.files[i].type,
          });
        }
      } else if (state.existingS3Images.length > 0) {
        images = state.existingS3Images;
      }
      return images;
    },
    getSharedWith(state) {
      return state.sharedWith;
    },
  },
  mutations: {
    setDraftId(state, id){
      state.draftId = id
    },
    setIsUploadingFilesToS3(state, loading){
      state.isUploadingFilesToS3 = loading
    },
    UPDATE_UPLOAD_PROGRESS(state, { order, progress }) {
      state.uploadProgress = {
        ...state.uploadProgress,
        [order]: progress,
      };
    },
    setSaveDraftStatus(state, status) {
      state.saveDraftStatus = status;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setAddress(state, address) {
      state.address = address;
    },
    setLink(state, link) {
      state.link = link;
    },
    setCategory(state, category) {
      state.category = category;
    },
    setSubcategory(state, subcategory) {
      state.subcategory = subcategory;
    },
    setExistingS3Images(state, images) {
      state.existingS3Images = images;
    },
    setCategoryDropdownSearchFilter(state, filter) {
      state.categoryDropdownSearchFilter = filter;
    },
    setCategoryDropdownSelectedOptionIndex(state, index) {
      state.categoryDropdownSelectedOptionIndex = index;
    },
    setFiles(state, files) {
      state.files = files;
    },
    setAspects(state, aspects) {
      state.aspects = aspects;
    },
    setSuggestedAspects(state, aspects) {
      state.suggestedAspects = aspects;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setSharedWith(state, shared) {
      state.sharedWith = shared;
    },
    saveFileOrder(state){
      if (state.files.length > 0) {
        let orderedFiles = [];
        for (let i = 0; i < state.files.length; i++) {
          orderedFiles.push({
            file: state.files[i].file,
            order: i,
            type: state.files[i].type,
          });
        }
        state.files = orderedFiles;
        console.log(`ordered files`, state.files)
      } else if (state.existingS3Images.length > 0) {
        for (let i = 0; i < state.existingS3Images.length; i++) {
          state.existingS3Images[i].order = i;
        }
        let sorted = state.existingS3Images;
        state.existingS3Images = sorted.sort((a, b) => (a.order > b.order ? 1 : -1));
        console.log(`ordered existing images`, state.existingS3Images)
      }
    },
    clear(state) {
      state.title = '';
      state.address = '';
      state.link = '';
      state.category = '';
      state.subcategory = '';
      state.categoryDropdownSearchFilter = '';
      state.categoryDropdownSelectedOptionIndex = -1;
      state.files = [];
      state.aspects = [];
      state.suggestedAspects = [];
      state.description = '';
      state.sharedWith = '';
      state.existingS3Images = [];
      state.createDraftStatus = 'waiting';
      state.draftId = '';
      state.uploadProgress = {};
      state.isUploadingFilesToS3 = false;
    },
  },
  actions: {
    async saveDraft({ dispatch, commit, getters, rootGetters }){
      const media = [];
      if (getters.getFiles != null) {
        for (let i = 0; i < getters.getFiles.length; i++) {
          const readyToUploadMedia = {
            name: getters.getFiles[i].file.name,
            order: getters.getFiles[i].order,
            type: getters.getFiles[i].type
          };
          media.push(readyToUploadMedia);
        }
      }

      // const oldDraft = this.slydepostDrafts.filter((d) => d.id === getters.getDraftId)[0];
      // const oldDraftIndex = this.slydepostDrafts.indexOf(oldDraft);
      const jsonBody = JSON.stringify({
        media,
        category: getters.getCategory,
        subcategory: getters.getSubcategory,
        title: getters.getTitle,
        link: getters.getLink,
        address: getters.getAddress,
        description: getters.getDescription,
        aspects: getters.getAspects,
        userId: rootGetters['authStoreModule/getUserId'],
        metaRating: getters.getMetaRating,
        sharedWith: getters.getSharedWith,
        userFirstName: rootGetters.getFirstName,
        userLastName: rootGetters.getLastName,
        // lists,
        username: rootGetters.getUsername,
        userProfilePictureUrl: rootGetters['authStoreModule/getProfilePhoto'],
        images: getters.getImages,
      });
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      return await client
        .put(`/post/draft/post/${getters.getDraftId}`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then(async (response) => {
          commit('setIsUploadingFilesToS3', true)
          for (const file of getters.getFiles) {
            for (const url of response.data.presignedUrlsForUpload) {
              if (url.includes(file.file.name)) {
                await client
                  .put(url, file.file, {
                    headers: {
                      'Content-Type': 'image/jpeg',
                    },
                    onUploadProgress: (progressEvent) => {
                      const progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      // Commit the progress to the store
                      commit('UPDATE_UPLOAD_PROGRESS', {
                        order: file.order,
                        progress,
                      });
                    },
                  })
                  .then((response) => {})
                  .catch((response) => {
                    console.error(`could not upload`, response);
                  });
              }
            }
          }
          gtag('event', 'slydepost-draft-updated', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
          // this.slydepostDrafts[oldDraftIndex] = response.data;
        })
        .catch((res) => {
          console.error(`could not create draft`, res);
          return undefined;
        });
    }
  },
};

export { editPostDraftStoreModule };
