import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Lists from '@/views/Lists.vue';
import SingleList from '@/views/SingleList.vue';
import Register from '@/views/SignUp/Register.vue';
import VerifyAccount from '@/views/SignUp/VerifyAccount.vue';
import Login from '@/views/SignIn/Login.vue';
import ForgotPassword from '@/views/SignIn/ForgotPassword.vue';
import ForgotPasswordSubmit from '@/views/SignIn/ForgotPasswordSubmit.vue';
import Settings from '@/views/Settings.vue';
import NewSlydepost from '@/views/NewSlydepost.vue';
import EditSlydepost from '@/views/EditSlydepost.vue';
import LoggedInUserProfile from '@/views/Profile/LoggedInUserProfile.vue';
import UserProfile from '@/views/Profile/UserProfile.vue';
import Community from '@/views/Community.vue';
import Legal from '@/views/Legal.vue';
import SlydepostView from '@/views/SlydepostView.vue';
import Notifications from '@/views/Notifications.vue';
import Search from '@/views/Search/Search.vue';
import store from '@/store/store';
import Congrats from '@/views/SignIn/Congrats.vue';
import Welcome from '@/views/SignUp/Welcome.vue';
import CompleteProfile from '@/views/SignUp/CompleteProfile.vue';
import Explore from '@/views/Explore.vue';
import Drafts from '@/views/Drafts.vue';
import BecomeAnInsider from '@/views/Insider/BecomeAnInsider.vue';
import ConfirmInsiderSignup from '@/views/Insider/ConfirmInsiderSignup.vue';
import InsiderDashboard from '@/views/Insider/InsiderDashboard.vue';
import VerifyCheckout from '@/views/Insider/VerifyCheckout.vue';
import ViewProducts from '@/views/Insider/Products/ViewProducts.vue';
import CreateInsiderProduct from '@/views/Insider/Products/CreateInsiderProduct.vue';
import EditInsiderProduct from '@/views/Insider/Products/EditInsiderProduct.vue';
import ViewPayments from '@/views/Insider/Products/ViewPayments.vue';
import ManageSubscriptions from '@/views/Insider/Products/ManageSubscriptions.vue';
import ManageSubscription from '@/views/Insider/Products/ManageSubscription.vue';
import IncomeOverview from '@/views/Insider/IncomeOverview.vue';
import Customers from '@/views/Insider/Customers.vue';
import Help from '@/views/Help.vue';
import EditDraft from '@/views/EditDraft.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: async (to, from, next) => {
      let user = store.getters['authStoreModule/getUser'];
      if (!user) {
        return next({
          name: 'Login',
        });
      }
      return next({
        name: 'Newsfeed',
      });
    },
  },
  {
    path: '/trending',
    name: 'Trending',
    component: Explore,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/community',
    name: 'Community',
    component: Community,
    meta: {
      requiresAuth: true,
      title: 'Community',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: LoggedInUserProfile,
    meta: {
      requiresAuth: true,
      title: 'Profile',
    },
  },
  {
    path: '/profile/:userId',
    name: 'UserProfile',
    component: UserProfile,
    beforeEnter: async (to, from, next) => {
      let loggedInUserId = store.getters['authStoreModule/getUserId'];
      if (to.params.userId === loggedInUserId) {
        return next({
          name: 'Profile',
        });
      }
      return next();
    },
    meta: {
      // requiresAuth: true,
      title: 'Slydepost',
    },
  },
  {
    path: '/newsfeed',
    name: 'Newsfeed',
    component: Home,
    meta: {
      requiresAuth: true,
      title: 'Slydepost',
    },
  },
  {
    path: '/lists',
    name: 'Lists',
    component: Lists,
    meta: {
      requiresAuth: true,
      title: 'Lists',
    },
  },
  {
    path: '/lists/:listId',
    name: 'SingleList',
    component: SingleList,
    meta: {
      requiresAuth: true,
      title: 'Slydepost',
    },
  },
  {
    path: '/slydepost/new',
    name: 'new-slydepost',
    component: NewSlydepost,
    meta: {
      requiresAuth: true,
      title: 'New Slydepost',
    },
  },
  {
    path: '/slydepost/edit',
    name: 'edit-slydepost',
    component: EditSlydepost,
    meta: {
      requiresAuth: true,
      title: 'Edit Slydepost',
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresSignedOut: true,
      title: 'Register',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresSignedOut: true,
      title: 'Login',
    },
  },
  {
    path: '/verify',
    name: 'VerifyAccount',
    component: VerifyAccount,
    meta: {
      requiresSignedOut: true,
      title: 'Verify',
    },
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresSignedOut: true,
      title: 'Forgot Password',
    },
  },
  {
    path: '/forgotPasswordSubmit',
    name: 'ForgotPasswordSubmit',
    component: ForgotPasswordSubmit,
    meta: {
      requiresSignedOut: true,
      title: 'Forgot Password',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      title: 'Settings',
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: Settings,
    meta: {
      requiresAuth: true,
      title: 'Help',
    },
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal,
    meta: {
      title: 'Legal',
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      requiresAuth: true,
      title: 'Search',
    },
  },
  {
    path: '/slydepost/:postId',
    name: 'ViewSlydepost',
    component: SlydepostView,
    meta: { requiresAuth: true, title: 'Slydepost' },
  },
  {
    path: '/slydepost/comment/:commentId',
    name: 'ViewSlydepostByCommentId',
    component: SlydepostView,
    meta: { requiresAuth: true, title: 'Slydepost' },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: { requiresAuth: true, title: 'Notifications' },
  },
  {
    path: '/congrats',
    name: 'Congrats',
    component: Congrats,
    meta: { requiresSignedOut: true, title: 'Slydepost' },
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: { requiresAuth: true, hideLayout: true, title: 'Slydepost' },
  },
  {
    path: '/complete/profile',
    name: 'Complete Profile',
    component: CompleteProfile,
    meta: { requiresAuth: true, hideLayout: true, title: 'Slydepost' },
  },
  {
    path: '/drafts',
    name: 'Drafts',
    component: Drafts,
    meta: {
      requiresAuth: true,
      title: 'Drafts',
    },
  },
  {
    path: '/draft/edit',
    name: 'Edit Draft',
    component: EditDraft,
    meta: {
      requiresAuth: true,
      title: 'Edit Draft',
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      title: 'Help'
    }
  },
  {
    path: '/become-insider',
    name: 'BecomeAnInsider',
    component: BecomeAnInsider,
    meta: { requiresAuth: true, title: 'Become An Insider' },
  },
  {
    path: '/insider',
    name: 'Insider',
    component: InsiderDashboard,
    meta: { requiresAuth: true, title: 'Insider Dashboard' },
  },
  {
    path: '/insider/confirm',
    name: 'ConfirmInsiderSignup',
    component: ConfirmInsiderSignup,
    meta: { requiresAuth: true },
  },
  {
    path: '/insider/products',
    name: 'ViewProducts',
    component: ViewProducts,
    meta: { requiresAuth: true, title: 'Products' },
  },
  {
    path: '/insider/product/create',
    name: 'CreateProduct',
    component: CreateInsiderProduct,
    meta: { requiresAuth: true, title: 'Create Product' },
  },
  {
    path: '/insider/product/edit',
    name: 'EditProduct',
    component: EditInsiderProduct,
    meta: { requiresAuth: true, title: 'Edit Product' },
  },
  {
    path: '/payments',
    name: 'Payments',
    component: ViewPayments,
    meta: { requiresAuth: true, title: 'Products' },
  },
  {
    path: '/subscriptions',
    name: 'ManageSubscriptions',
    component: ManageSubscriptions,
    meta: { requiresAuth: true, title: 'Manage Subscriptions' },
  },
  {
    path: '/subscription',
    name: 'ManageSubscription',
    component: ManageSubscription,
    meta: { requiresAuth: true, title: 'Manage Subscription' },
  },
  {
    path: '/checkout/verify',
    name: 'VerifyCheckout',
    component: VerifyCheckout,
    meta: { requiresAuth: true, title: 'Checkout' },
  },
  {
    path: '/insider/income',
    name: 'IncomeOverview',
    component: IncomeOverview,
    meta: { requiresAuth: true, title: 'Income Overview' },
  },
  {
    path: '/insider/customers',
    name: 'InsiderCustomers',
    component: Customers,
    meta: { requiresAuth: true, title: 'Customers' },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  // If gtag is defined, send the page_view event
  if (typeof gtag !== 'undefined') {
    gtag('event', 'page_view', {
      page_title: document.title,
      page_path: to.fullPath,
      // page_location: window.location.href, // Optionally include location
    });
  }
});

router.beforeResolve(async (to, from, next) => {
  // restrict pages if user is not signed in
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let user = store.getters['authStoreModule/getUser'];
    if (!user) {
      return next({
        name: 'Login',
      });
    } else {
      return next();
    }
  }
  // don't allow user to see page if they should be signed out
  // i.e. don't let user get to sign in page if they are already signed in
  if (to.matched.some((record) => record.meta.requiresSignedOut)) {
    let user = store.getters['authStoreModule/getUser'];
    if (!user) {
      return next();
    } else {
      return next({
        name: 'Home',
      });
    }
  }
  return next();
});

const DEFAULT_TITLE = 'Slydepost';
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
