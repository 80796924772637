<template>
  <div class='flex flex-row gap-2 justify-center items-center mx-4'>
    <div class="w-full  h-4 rounded border border-dark-blue relative overflow-hidden">
      <span :style="{ width: progress + '%' }" class='bg-moonstone h-full block absolute'></span>
    </div>
    <div class='w-12'>
      <p>{{progress}}%</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      validator: (value) => value >= 0 && value <= 100,
    },
  },
};
</script>