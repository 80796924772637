<template>
  <div class="flex flex-col gap-4 md:gap-6 w-full px-4 py-4">
    <div class="flex flex-col gap-2">
      <div class="flex flex-col md:flex-row items-start justify-center md:justify-start md:items-center gap-4">
        <img class="hidden md:inline w-12 h-12 rounded-2xl" :src="profilePictureUrl" />
        <div class="flex flex-row justify-between w-full items-center">
          <p class="review-text text-lg text-blue">What do you want to review?</p>
          <font-awesome-icon @click="$emit('exit')" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
        </div>
      </div>
      <div v-show="this.showMissingTitleError === true">
        <p class="text-red-500">{{ this.missingTitleErrorMessage }}</p>
      </div>
      <div>
        <input v-model="this.title" type="text" placeholder="Title" class="text-blue" />
      </div>
    </div>
    <div>
      <div class="flex flex-col gap-2">
        <p class="text-start text-lg text-blue">Choose a category</p>
        <div v-show="this.showMissingCategoryError === true">
          <p class="text-red-500">{{ this.missingCategoryErrorMessage }}</p>
        </div>
        <div class="relative h-8">
          <CategoryDropdown class="absolute w-full" :placeholder="'Find a category'" />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div>
        <p class="text-start text-lg text-blue mt-4">Tell us more</p>
      </div>
      <div>
        <input v-model="this.address" class="text-blue" type="text" placeholder="Location" />
      </div>
      <div>
        <input v-model="this.link" class="text-blue" type="text" placeholder="Link" />
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <p class="text-sm">Select up to 5 photos</p>
      <UploadMedia :text="'Upload Media'" />
    </div>
    <div v-if='isUploadingDraft'>
      <div v-for='file in files' :key='file.order' class='pb-4'>
        <ProgressBar :progress='getDraftUploadProgress(file.order)'/>
      </div>
    </div>
    <div>
      <div class="relative float-right flex flex-row gap-2 items-center">
        <LoaderButton
          :icon-left-side="false"
          :show-error-icon="false"
          :status="createDraftStatus"
          :waiting-text="''"
          :in-progress-text="'Saving'"
          :done-text="'Saved!'"
          :error-text="'Try Again?'"
          :waiting-icon="'save'"
          @click="createDraft"
        />
        <font-awesome-icon
          @click="nextModal"
          class="text-3xl cursor-pointer"
          :color="navigationArrowColor"
          :icon="['fas', 'arrow-right']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UploadMedia from '@/components/CreateSlydepost/CreateSlydepostUploadMedia.vue';
import CategoryDropdown from '@/components/CreateSlydepost/CategoryDropdown.vue';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import ProgressBar from '@/components/Utility/ProgressBar.vue';
import { mapGetters } from 'vuex';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore';

export default {
  name: 'CreateSlydepostInfo',
  emits: ['next'],
  data() {
    return {
      showMissingTitleError: false,
      showMissingCategoryError: false,
      missingTitleErrorMessage: 'Please enter a title',
      missingCategoryErrorMessage: 'Please select a category',
    };
  },
  components: {
    CategoryDropdown,
    UploadMedia,
    LoaderButton,
    ProgressBar
  },
  setup() {
    const slydepostDraftStore = useSlydepostDraftStore();
    return { slydepostDraftStore };
  },
  methods: {
    nextModal() {
      console.log(`going to next modal`);
      if (this.title == null || this.title.trim() === '') {
        this.showMissingTitleError = true;
        return;
      }
      if (this.category == null || this.category === '') {
        this.showMissingCategoryError = true;
        return;
      }
      this.saveDataIntoDraft();
      this.$emit('next');
    },
    async createDraft() {
      this.saveDataIntoDraft();
      await this.$store.dispatch('createPostDraftStoreModule/createDraft');
      this.$emit('draft');
    },
    getDraftUploadProgress(order){
      let progress = this.$store.getters['createPostDraftStoreModule/getUploadProgress']
      return progress[order]
    },
    saveDataIntoDraft(){
      this.$store.commit('createPostDraftStoreModule/setTitle', this.title)
      this.$store.commit('createPostDraftStoreModule/setCategory', this.category)
      this.$store.commit('createPostDraftStoreModule/setSubcategory', this.subcategory)
      this.$store.commit('createPostDraftStoreModule/setAddress', this.address)
      this.$store.commit('createPostDraftStoreModule/setLink', this.link)
      this.$store.commit('createPostDraftStoreModule/setFiles', this.files)
      this.$store.commit('createPostDraftStoreModule/setExistingS3Images', this.existingMedia)
      this.$store.commit('createPostDraftStoreModule/setSharedWith', this.sharedWith)
    }
  },
  computed: {
    ...mapGetters({
      isUploadingDraft: 'createPostDraftStoreModule/getIsUploadingFilesToS3',
      files: 'createPostStoreModule/getFiles',
      existingMedia: 'createPostStoreModule/getExistingS3Images',
      sharedWith: 'createPostStoreModule/getSharedWith',
      createDraftStatus: 'createPostDraftStoreModule/getCreateDraftStatus',
      profilePictureUrl: 'getProfilePictureUrl'
    }),
    title: {
      get() {
        return this.$store.getters['createPostStoreModule/getTitle'];
      },
      set(title) {
        this.$store.commit('createPostStoreModule/setTitle', title);
      },
    },
    category: {
      get() {
        return this.$store.getters['createPostStoreModule/getCategory'];
      },
    },
    subcategory: {
      get() {
        return this.$store.getters['createPostStoreModule/getSubcategory'];
      },
    },
    address: {
      get() {
        return this.$store.getters['createPostStoreModule/getAddress'];
      },
      set(address) {
        this.$store.commit('createPostStoreModule/setAddress', address);
      },
    },
    link: {
      get() {
        return this.$store.getters['createPostStoreModule/getLink'];
      },
      set(link) {
        this.$store.commit('createPostStoreModule/setLink', link);
      },
    },
    navigationArrowColor() {
      if (this.title == null || this.title.trim() === '') {
        return '#E0E0E0';
      }
      if (this.category == null || this.category === '') {
        return '#E0E0E0';
      }
      return '#30C3E3';
    },
  },
};
</script>
