import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import './styles/input.css';
import router from './router';
import store from './store/store.js';
import Sortable from 'vue-sortable';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import VueLazyLoad from 'vue3-lazyload';
import withUUID from 'vue-uuid';
import * as Sentry from '@sentry/vue';
import VueNumberFormat from '@coders-tm/vue-number-format'


Amplify.configure(awsExports);
import vClickOutside from 'click-outside-vue3';
import InfiniteLoading from 'v3-infinite-loading';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { faCircleXmark as faCircleXRegular } from '@fortawesome/free-regular-svg-icons';
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { faGrip } from '@fortawesome/free-solid-svg-icons';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faGem } from '@fortawesome/free-regular-svg-icons'
import { faBox } from '@fortawesome/free-solid-svg-icons';

library.add(faHeart);
library.add(faHeartRegular);
library.add(faCircleXRegular);
library.add(faXmark);
library.add(faCircle);
library.add(faAngleDown);
library.add(faAngleUp);
library.add(faArrowRight);
library.add(faArrowDown);
library.add(faPhone);
library.add(faArrowUp);
library.add(faArrowLeft);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faPencil);
library.add(faSearch);
library.add(faBars);
library.add(faEllipsis);
library.add(faUser);
library.add(faLocationDot);
library.add(faX);
library.add(faEyeSlash);
library.add(faCircleNotch);
library.add(faCheck);
library.add(faFloppyDisk);
library.add(faLock);
library.add(faCircleExclamation);
library.add(faFilter);
library.add(faBell);
library.add(faCircleInfo);
library.add(faGear);
library.add(faLifeRing);
library.add(faBellRegular);
library.add(faHouse);
library.add(faListUl);
library.add(faUserGroup);
library.add(faRightFromBracket);
library.add(faPause);
library.add(faGrip);
library.add(faFire);
library.add(faSpinner);
library.add(faGem);
library.add(faBox);
import { dom } from '@fortawesome/fontawesome-svg-core';

dom.watch();

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const G_TAG = process.env.G_TAG;

const app = withUUID(
  createApp({
    extends: App,
    beforeCreate() {
      this.$store.commit('authStoreModule/initializeUser');
    },
  })
);

const vuetify = createVuetify({
  components,
  directives,
});

Sentry.init({
  app,
  dsn: 'https://323972cc6b9aeebce74d01ba9eeb5a0b@o4507568375595008.ingest.us.sentry.io/4507568377692160',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/slydepost\.com/, /^https:\/\/www.slydepost\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('infinite-loading', InfiniteLoading);
app.use(vuetify);
app.use(router);
app.use(store);
app.use(Sortable);
app.use(vClickOutside);
app.use(createPinia().use(piniaPluginPersistedstate));
app.use(VueNumberFormat)

import VueCookies from 'vue3-cookies';

app.use(VueCookies);
app.use(VueLazyLoad);

import VueVirtualScroller from 'vue-virtual-scroller';
app.use(VueVirtualScroller);
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

app.directive('focus', {
  inserted: function (el) {
    // Focus the element
    el.focus();
  },
  update: function (el, binding) {
    var value = binding.value;
    if (value) {
      app.nextTick(function () {
        el.focus();
      });
    }
  },
});

app.mount('#app');

export { store };
