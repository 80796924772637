import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const createPostDraftStoreModule = {
  namespaced: true,
  state: {
    title: '',
    address: '',
    link: '',
    category: '',
    subcategory: '',
    categoryDropdownSearchFilter: '',
    categoryDropdownSelectedOptionIndex: -1,
    draftId: '',
    files: [],
    uploadProgress: {},
    isUploadingFilesToS3: false,
    aspects: [],
    existingS3Images: [],
    suggestedAspects: [],
    description: '',
    sharedWith: '',
    createDraftStatus: 'waiting', // waiting, inProgress, done, error
  },
  getters: {
    getIsUploadingFilesToS3(state){
      return state.isUploadingFilesToS3;
    },
    getUploadProgress(state){
      return state.uploadProgress
    },
    getCreateDraftStatus(state) {
      return state.createDraftStatus;
    },
    getTitle(state) {
      return state.title;
    },
    getAddress(state) {
      return state.address;
    },
    getLink(state) {
      return state.link;
    },
    getCategory(state) {
      return state.category;
    },
    getSubcategory(state) {
      return state.subcategory;
    },
    getFiles(state) {
      return state.files;
    },
    getAspects(state) {
      return state.aspects;
    },
    getDescription(state) {
      return state.description;
    },
    getMetaRating(state) {
      if(state.aspects.length === 0){
        return 0;
      }
      let totalAspectRating = 0;
      let numberOfAspects = 0;
      state.aspects.forEach((aspect) => {
        if (aspect.selected === true) {
          totalAspectRating += parseInt(aspect.rating);
          numberOfAspects += 1;
        }
      });
      const finalRating = Math.round((totalAspectRating / numberOfAspects) * 10) / 10;
      return finalRating;
    },
    getSharedWith(state) {
      return state.sharedWith;
    },
  },
  mutations: {
    setIsUploadingFilesToS3(state, loading){
      state.isUploadingFilesToS3 = loading
    },
    UPDATE_UPLOAD_PROGRESS(state, { order, progress }) {
      state.uploadProgress = {
        ...state.uploadProgress,
        [order]: progress,
      };
    },
    setCreateDraftStatus(state, status) {
      state.createDraftStatus = status;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setAddress(state, address) {
      state.address = address;
    },
    setLink(state, link) {
      state.link = link;
    },
    setCategory(state, category) {
      state.category = category;
    },
    setSubcategory(state, subcategory) {
      state.subcategory = subcategory;
    },
    setExistingS3Images(state, images) {
      state.existingS3Images = images;
    },
    setFiles(state, files) {
      state.files = files;
    },
    setAspects(state, aspects) {
      state.aspects = aspects;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setSharedWith(state, shared) {
      state.sharedWith = shared;
    },
    clear(state) {
      state.title = '';
      state.address = '';
      state.link = '';
      state.category = '';
      state.subcategory = '';
      state.categoryDropdownSearchFilter = '';
      state.categoryDropdownSelectedOptionIndex = -1;
      state.files = [];
      state.aspects = [];
      state.suggestedAspects = [];
      state.description = '';
      state.sharedWith = '';
      state.existingS3Images = [];
      state.createDraftStatus = 'waiting';
      state.draftId = '';
      state.uploadProgress = {};
      state.isUploadingFilesToS3 = false;
    },
  },
  actions: {
    async createDraft({ dispatch, commit, getters, rootGetters }) {
      if (getters.getCreateDraftStatus === 'inProgress') {
        return;
      }

      commit('setCreateDraftStatus', 'inProgress');
      const media = [];

      // Collect media info
      if (getters.getFiles != null) {
        for (let i = 0; i < getters.getFiles.length; i++) {
          const readyToUploadMedia = {
            name: getters.getFiles[i].file.name,
            order: getters.getFiles[i].order,
            type: getters.getFiles[i].type
          };
          media.push(readyToUploadMedia);
        }
      }

      // Build the request body
      const jsonBody = JSON.stringify({
        media,
        category: getters.getCategory,
        subcategory: getters.getSubcategory,
        title: getters.getTitle,
        link: getters.getLink,
        address: getters.getAddress,
        description: getters.getDescription,
        aspects: getters.getAspects,
        userId: rootGetters['authStoreModule/getUserId'],
        metaRating: getters.getMetaRating,
        sharedWith: getters.getSharedWith.toUpperCase(),
        userFirstName: rootGetters['authStoreModule/getFirstName'],
        userLastName: rootGetters['authStoreModule/getLastName'],
        username: rootGetters['authStoreModule/getUser'].username,
        userProfilePictureUrl: rootGetters['authStoreModule/getProfilePhoto'],
      });

      console.log(`creating draft with json body`, jsonBody);

      const loggedInUserId = rootGetters['authStoreModule/getUserId'];

      try {
        // Await the API call
        const response = await client.post(
          `/post/draft/post`,
          jsonBody,
          {
            withCredentials: true,
            headers: {
              'request-id': uuid.v1(),
              'User-Id': loggedInUserId,
            },
          }
        );

        console.log(`backend response`, response.data);

        commit('setIsUploadingFilesToS3', true);

        // For each file, find a matching presigned URL and upload
        for (const file of getters.getFiles) {
          console.log(`checking for uploading file`, file);

          for (const url of response.data.presignedUrlsForUpload) {
            if (url.includes(file.file.name)) {
              console.log(`uploading file to s3`);
              // Await the file upload
              try {
                await client.put(url, file.file, {
                  headers: {
                    'Content-Type': 'image/jpeg',
                  },
                  onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // Commit the progress to the store
                    commit('UPDATE_UPLOAD_PROGRESS', {
                      order: file.order,
                      progress,
                    });
                  },
                });
              } catch (err) {
                console.error(`could not upload`, err);
              }
            }
          }
        }

        commit('setIsUploadingFilesToS3', false);
        commit('setCreateDraftStatus', 'done');
        gtag('event', 'slydepost-draft-created', {
          event_category: 'Slydepost',
          event_label: 'Label',
          value: 1,
        });
        // Return the data for use by the caller
        return response.data;
      } catch (error) {
        // Handle error
        console.error(`could not create draft`, error);
        commit('setIsUploadingFilesToS3', false);
        commit('setCreateDraftStatus', 'error');
        return undefined;
      }
    },
  },
};

export { createPostDraftStoreModule };
