<template>
  <div class="flex flex-col gap-2 w-full px-4 py-4">
    <div class="flex items-center gap-4">
      <img class="hidden md:inline w-12 h-12 rounded-2xl" :src="profilePictureUrl" />
      <div class="text-left flex flex-col gap-1 w-full">
        <div class="flex flex-row justify-between items-center w-full">
          <p class="text-blue text-lg">What do you want to discuss?</p>
          <font-awesome-icon @click="$emit('exit')" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
        </div>
        <p class="text-moonstone text-base italic">Select up to 5 aspects to review</p>
        <div>
          <p :v-show="this.showError === true" class="text-red-500">
            {{ this.errorMessage }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <hr />
    </div>
    <div class="flex flex-col gap-4">
      <p class="text-left italic text-light-gray">Suggested Aspects</p>
      <div class="inline-flex flex-wrap gap-3">
        <div v-for="aspect in this.aspects" :key="aspect.name" :class="this.isAspectSelected(aspect)">
          <CreateSlydepostAspect :name="aspect.name" />
        </div>
        <div v-if="this.totalAspectsSelected < maxAspects">
          <button
            v-show="this.showAspectInput === false"
            @click="this.setEditing()"
            class="text-base h-10 bg-blue items-center rounded-xl text-white text-center px-5 placeholder-white"
          >
            Create your own +
          </button>
          <div class="relative">
            <input
              ref="editInput"
              v-show="this.showAspectInput === true"
              class="text-base h-10 items-center rounded-xl pl-5 pr-8"
              type="text"
              placeholder="Create your own +"
              v-model="this.newAspect"
              @keydown.enter="addAspect"
            />
            <p
              v-if="this.newAspect.length > 0"
              @click="this.addAspect"
              class="absolute bottom-2 right-4 text-blue font-bold"
            >
              +
            </p>
          </div>
        </div>
      </div>
      <div v-if='isUploadingDraft'>
        <div v-for='file in files' :key='file.order' class='pb-4'>
          <ProgressBar :progress='getDraftUploadProgress(file.order)'/>
        </div>
      </div>
      <div class="flex pb-2 justify-between items-center">
        <div>
          <font-awesome-icon
            @click="previousModal"
            :color="'#015D79'"
            class="cursor-pointer text-3xl"
            :icon="['fas', 'arrow-left']"
          />
        </div>
        <div class="flex flex-row gap-2 items-center">
          <LoaderButton
            :icon-left-side="false"
            :show-error-icon="false"
            :status="createDraftStatus"
            :waiting-text="''"
            :in-progress-text="'Saving'"
            :done-text="'Saved!'"
            :error-text="'Try Again?'"
            :waiting-icon="'save'"
            @click="createDraft"
          />
          <font-awesome-icon
            @click="nextModal"
            class="cursor-pointer text-3xl"
            :color="navigationArrowColor"
            :icon="['fas', 'arrow-right']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import focus from '@/directives/focus';
import categoryAspectMap from '@/constants/category-aspect-map.json';
import CreateSlydepostAspect from '@/components/CreateSlydepost/CreateSlydepostAspect.vue';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateSlydepostAspects',
  directives: {
    focus,
  },
  emits: ['next', 'back'],
  data() {
    return {
      newAspect: '',
      maxAspects: 5,
      showError: false,
      errorMessage: '',
      showAspectInput: false,
    };
  },
  components: { CreateSlydepostAspect, LoaderButton },
  setup() {
    const slydepostDraftStore = useSlydepostDraftStore();
    return { slydepostDraftStore };
  },
  methods: {
    async createDraft() {
      this.saveDataIntoDraft();
      await this.$store.dispatch('createPostDraftStoreModule/createDraft')
      this.$emit('draft');
    },
    setEditing() {
      this.showAspectInput = true;
      let el = this.$refs.editInput;
      this.$nextTick(() => {
        el.focus();
      });
    },
    isAspectSelected(selectedAspect) {
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].name === selectedAspect.name) {
          if (this.aspects[i].selected === true) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
    addAspect() {
      // check if added aspect is already in list of aspects
      // do nothing if already selected
      // else mark as selected
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].name === this.newAspect) {
          if (this.aspects[i].selected === true) {
            this.newAspect = '';
            this.showAspectInput = false;
            return;
          } else {
            this.aspects[i].selected = true;
            this.newAspect = '';
            this.showAspectInput = false;
            return;
          }
        }
      }
      let aspect = {
        name: this.newAspect,
        rating: 5,
        description: '',
        selected: true,
      };
      this.aspects.push(aspect);
      this.newAspect = '';
      this.showAspectInput = false;
    },
    removeAspect(aspect) {
      for (let i = 0; i < this.aspects.length; i++) {
        if (this.aspects[i].name === aspect.name) {
          this.aspects[i].selected = false;
        }
      }
    },
    previousModal() {
      this.saveDataIntoDraft();
      this.$emit('back');
    },
    nextModal() {
      if (this.selectedAspects.length < 1) {
        this.showError = true;
        this.errorMessage = 'Please select an aspect';
        return;
      }
      this.saveDataIntoDraft();
      this.$emit('next');
    },
    saveDataIntoDraft(){
      this.$store.commit('createPostDraftStoreModule/setAspects', this.selectedAspects)
      this.$store.commit('createPostDraftStoreModule/setSharedWith', this.sharedWith)
    },
    getDraftUploadProgress(order){
      let progress = this.$store.getters['createPostDraftStoreModule/getUploadProgress']
      return progress[order]
    },
  },
  mounted() {
    // get suggested aspects for the category
    let key = '';
    if (this.subcategory != null) {
      key = `${this.category}.${this.subcategory}`;
    } else {
      key = `${this.category}`;
    }
    let categoryAspects = [];
    if (categoryAspectMap[key] == null) {
      categoryAspects = ['Food & Drink', 'Vibe', 'Service', 'Price', 'Value', 'Experience', 'Accessibility'];
    } else {
      categoryAspects = categoryAspectMap[key];
    }
    // add the aspects to the suggestedAspects array
    for (let i = 0; i < categoryAspects.length; i++) {
      let newAspect = {
        name: categoryAspects[i],
        rating: 5,
        description: '',
        selected: false,
      };
      this.suggestedAspects.push(newAspect);
    }

    // check if the suggestedAspect has been selected already
    // if already selected, we don't add it to be picked from
    // if not selected, we add it to the aspects so it could be picked

    for (let i = 0; i < this.suggestedAspects.length; i++) {
      let addSuggestedAspect = true;
      for (let j = 0; j < this.aspects.length; j++) {
        if (this.suggestedAspects[i].name === this.aspects[j].name) {
          addSuggestedAspect = false;
        }
      }
      if (addSuggestedAspect) {
        this.aspects.push(this.suggestedAspects[i]);
      }
    }
  },
  computed: {
    ...mapGetters({
      isUploadingDraft: 'createPostDraftStoreModule/getIsUploadingFilesToS3',
      files: 'createPostStoreModule/getFiles',
    }),
    createDraftStatus: {
      get() {
        return this.$store.getters['createPostDraftStoreModule/getCreateDraftStatus'];
      },
      set(status) {
        this.$store.commit('createPostDraftStoreModule/setCreateDraftStatus', status);
      },
    },
    aspects: {
      get() {
        return this.$store.getters['createPostStoreModule/getAspects'];
      },
      set(aspects) {
        this.$store.commit('createPostStoreModule/setAspects', aspects);
      },
    },
    selectedAspects: {
      get() {
        return this.$store.getters['createPostStoreModule/getSelectedAspects'];
      },
    },
    suggestedAspects: {
      get() {
        return this.$store.getters['createPostStoreModule/getSuggestedAspects'];
      },
      set(aspects) {
        this.$store.commit('createPostStoreModule/setSuggestedAspects', aspects);
      },
    },
    category: {
      get() {
        return this.$store.getters['createPostStoreModule/getCategory'];
      },
    },
    subcategory: {
      get() {
        return this.$store.getters['createPostStoreModule/getSubcategory'];
      },
    },
    profilePictureUrl: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
    },
    navigationArrowColor() {
      if (this.selectedAspects.length === 0) {
        return '#E0E0E0';
      }
      return '#30C3E3';
    },
    totalAspectsSelected: {
      get() {
        return this.$store.getters['createPostStoreModule/getTotalAspectsSelected'];
      },
    },
  },
};
</script>
