<template>
  <div class='loading'>
    <div class='flex flex-row gap-2 items-center px-4 py-2'>
      <div id='profile-picture' class='description rounded-2xl bg-light-gray h-12 w-12'></div>
      <div class='flex flex-col gap-1 flex-grow'>
        <div
          id='name'
          class='description h-4 w-2/3'
        />
        <div
          id='date'
          class='description h-4 w-1/3'
        />
      </div>
    </div>
    <div id='main-image' class='description h-48 px-4'></div>
    <div class='flex flex-col gap-2 px-4 py-2 items-start'>
      <div
        id='category'
        class='description w-1/2 h-4'
      />
      <div
        id='title'
        class='description w-3/4 h-4'
      />
      <div
        id='location'
        class='description w-1/2 h-4'
      />
      <div
        id='description'
        class='description w-1/2 h-4'
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlydepostSkeleton',
};
</script>

<style scoped>
.loading .image,
.loading h4,
.loading .description {
  background-color: rgb(189 189 189 / var(--tw-bg-opacity));
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) rgb(189 189 189 / var(--tw-bg-opacity));
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
</style>