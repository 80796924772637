<template>
  <div class="relative inline-block m-1">
    <div v-if="thumbnailDataUrl">
      <img :src="thumbnailDataUrl" class='object-contain max-h-28' alt="Video Thumbnail" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoPreview',
  props: ['file', 'thumbnail'],
  emits: [],
  data() {
    return {
      thumbnailDataUrl: null,
    };
  },
  methods: {
    generateThumbnail() {
      if(this.thumbnail != null && this.thumbnail !== ''){
        this.thumbnailDataUrl = this.thumbnail;
        return;
      }

      console.log(`generating thumbnail for video`)
      const video = document.createElement('video');
      video.preload = 'metadata';

      const fileURL = URL.createObjectURL(this.file.file);
      video.src = fileURL;

      video.onloadedmetadata = () => {
        // Seek to 1 second (adjust as needed)
        video.currentTime = Math.min(1, video.duration / 2);

        video.onseeked = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          this.thumbnailDataUrl = canvas.toDataURL('image/jpeg');

          // Clean up
          URL.revokeObjectURL(fileURL);
        };
      };

      video.onerror = () => {
        console.error('Error loading video file for thumbnail generation.');
        URL.revokeObjectURL(fileURL);
      };
    },
  },
  mounted() {
    this.generateThumbnail();
  },
};
</script>
