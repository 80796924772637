<template>
  <div class="relative bg-white">
    <div ref="tempContainer" class='hidden absolute -top-[9999px]'></div>

    <div class=''>
      <div
        :style="media && media.length ? `height: ${maxHeight}px` : ''"
        class="flex justify-center items-center"
      >
        <img
          v-if="currentMediaFileType === `IMAGE`"
          class='object-contain block min-h-[100px] max-h-[380px] lg:max-h-[680px]'
          :class='media && media.length ? `` : `object-cover`'
          v-lazy="currentMedia"
          alt=""
        />
        <VideoPlayer
          v-if='currentMediaFileType === `VIDEO`'
          class='object-contain block min-h-[100px] max-h-[380px] lg:max-h-[680px]'
          :source='currentMedia'
        />
      </div>
    </div>
    <div v-if="this.files != null && this.files.length > 1" class="px-2 pt-2">
      <div class="flex flex-row justify-center flex-nowrap gap-2 overflow-x-auto">
        <div
          v-for="(image, index) in thumbnails"
          :key="index"
          class="flex-shrink-0"
          @click="activateImage(index)"
        >
          <MediaCarouselThumbnail :file="image" :type="image.type" />
        </div>
      </div>
    </div>
    <div v-else-if="this.media != null && this.media.length > 1" class='px-2 pt-2'>
      <div class="flex flex-row justify-center flex-nowrap gap-2 overflow-x-auto">
        <div
          v-for="(image, index) in thumbnails"
          :key="index"
          class='flex items-center justify-center cursor-pointer'
          @click="activateImage(index)"
        >
          <MediaCarouselThumbnail :media="image" :type="image.type" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaCarouselThumbnail from '@/components/ViewSlydepost/MediaCarouselThumbnail.vue';
import VideoPlayer from '@/components/ViewSlydepost/VideoPlayer.vue';

export default {
  name: 'MediaCarousel',
  components: {
    MediaCarouselThumbnail,
    VideoPlayer
  },
  data() {
    return {
      defaultMaxHeight: 100,
      activeImage: 0,
      maxHeight: 100,
      currentMediaFileType: '',

    };
  },
  props: {
    media: {
      type: Array,
      note: `array of media objects with fields 'type', 'order', 'presignedUrl'`
    },
    files: {
      type: Array,
      note: `array of file objects with fields 'type', 'order', 'file'`
    },
    isPreview: {
      type: Boolean,
      note: `true/false if this is as a preview slydepost during create/edit or just a regular viewed slydepost`
    }
  },
  computed: {
    sortedMedia() {
      if(this.media != null && this.media.length > 0){
        let media = [...this.media];
        let sorted = media.sort((a, b) => a.order - b.order);
        return sorted;
      }
      return []
    },
    sortedFiles(){
      if(this.files != null && this.files.length > 0){
        let files = [...this.files];
        let sorted = files.sort((a, b) => a.order - b.order);
        return sorted;
      }
      return []
    },
    thumbnails(){
      if(this.files != null && this.files.length > 0){
        return this.sortedFiles;
      } else if(this.media != null && this.media.length > 0){
        return this.sortedMedia;
      } else {
        return [];
      }
    },
    currentMedia() {
      console.log(`current current image`)
      // there is at least 1 image to display
      if (this.media && this.media.length > 0) {
        console.log(`files is not empty`)
        if(this.sortedMedia[this.activeImage].type === 'IMAGE'){
          console.log(`current media is an image`)
          this.currentMediaFileType = this.sortedMedia[this.activeImage].type;
          return this.sortedMedia[this.activeImage].presignedUrl;
        } else {
          console.log(`current media is a video`)
          this.currentMediaFileType = this.sortedMedia[this.activeImage].type;
          const currentMedia = this.sortedMedia[this.activeImage];
          return currentMedia;
        }
      } else if (this.files && this.files.length > 0){
        if(this.sortedFiles[this.activeImage].type === 'IMAGE'){
          console.log(`current file is an image`)
          this.currentMediaFileType = this.sortedFiles[this.activeImage].type;
          return URL.createObjectURL(this.sortedFiles[this.activeImage].file);
        } else {
          console.log(`current file is a video`)
          this.currentMediaFileType = this.sortedFiles[this.activeImage].type;
          return {
            blobUrl: URL.createObjectURL(this.sortedFiles[this.activeImage].file)
          }
        }
      }
      // no media are being uploaded, display default background
      else {
        console.log(`no files, getting default image`)
        this.currentMediaFileType = 'IMAGE';
        return require('@/assets/no-photo-background.png');
      }
    },
  },
  methods: {
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
    },
    cardImgClass() {
      if (this.media == null || this.media.length === 0) {
        return '';
      } else if (this.media.length === 1) {
        return 'block object-contain min-h-[100px] max-h-[680px]';
      } else {
        return 'card-img';
      }
    },
    isMobileView() {
      return window.innerWidth < 640;
    },
    async calculateMaxHeight() {
      let isMobile = this.isMobileView();
      if (this.isPreview && isMobile) {
        this.maxHeight = 380;
        return;
      } else if (this.isPreview && !isMobile) {
        this.maxHeight = 680;
        return;
      }
      // no media to display, use height of default background image
      if((this.media == null || this.media.length == 0) && (this.files == null || this.files.length == 0)){
        this.maxHeight = 100;
      } else {
          if(isMobile){
            this.maxHeight = 380;
          } else {
            this.maxHeight = 680;
          }
      }
      return;
    },
  },
  watch: {
    media: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.calculateMaxHeight();
        }
      },
    },
  },
  mounted(){
    if(this.files != null && this.files.length > 0){
      this.currentMediaFileType = this.files[0].type
    }
    else if(this.media != null && this.media.length > 0){
      this.currentMediaFileType = this.media[0].type
    } else {
      this.currentMediaFileType = 'IMAGE';
    }
  }
};
</script>