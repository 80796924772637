<template>
  <div v-if="thumbnailDataUrl">
    <img :src="thumbnailDataUrl" class='object-contain max-h-20' alt="Video Thumbnail" />
  </div>
</template>

<script>
export default {
  name: 'MediaCarouselThumbnail',
  props: {
    type: '',
    file: {},
    media: {},
  },
  data(){
    return {
      thumbnailDataUrl: ''
    }
  },
  methods: {
    createVideoThumbnailFromFile() {
      console.log(`generating thumbnail for video`)
      const video = document.createElement('video');
      video.preload = 'metadata';

      const fileURL = URL.createObjectURL(this.file.file);
      video.src = fileURL;

      video.onloadedmetadata = () => {
        // Seek to 1 second (adjust as needed)
        video.currentTime = Math.min(1, video.duration / 2);

        video.onseeked = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          this.thumbnailDataUrl = canvas.toDataURL('image/jpeg');

          // Clean up
          URL.revokeObjectURL(fileURL);
        };
      };

      video.onerror = () => {
        console.error('Error loading video file for thumbnail generation.');
        URL.revokeObjectURL(fileURL);
      };
    },
    createVideoThumbnailFromPresignedUrl() {
      this.thumbnailDataUrl = this.media.videoThumbnail;
      // console.log(`generating thumbnail for video from presigned url`);
      // const video = document.createElement('video');
      // video.preload = 'metadata';
      // video.crossOrigin = 'anonymous'; // Set the crossorigin attribute
      //
      // const fileURL = this.media.presignedUrl;
      // video.src = fileURL;
      //
      // video.onloadedmetadata = () => {
      //   // Seek to 1 second (adjust as needed)
      //   video.currentTime = Math.min(1, video.duration / 2);
      //
      //   video.onseeked = () => {
      //     const canvas = document.createElement('canvas');
      //     canvas.width = video.videoWidth;
      //     canvas.height = video.videoHeight;
      //     const ctx = canvas.getContext('2d');
      //     ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      //
      //     this.thumbnailDataUrl = canvas.toDataURL('image/jpeg');
      //
      //     // Clean up
      //     URL.revokeObjectURL(fileURL);
      //   };
      // };
      //
      // video.onerror = () => {
      //   console.error('Error loading video file for thumbnail generation.');
      //   URL.revokeObjectURL(fileURL);
      // };
    },
    createImageThumbnail() {
      console.log(`generating image thumbnail`, this.file)
      console.log(`generating image thumbnail. file:`, this.file.file)
      this.thumbnailDataUrl =  URL.createObjectURL(this.file.file);
    },
    getImageThumbnail(){
      this.thumbnailDataUrl = this.media.presignedUrl;
    }
  },
  mounted() {
    if(this.type === 'VIDEO' && this.file != null){
      this.createVideoThumbnailFromFile();
    } else if (this.type === 'IMAGE' && this.file != null){
      this.createImageThumbnail();
    } else if (this.type === 'VIDEO' && this.media != null){
      this.createVideoThumbnailFromPresignedUrl();
    } else if (this.type === 'IMAGE' && this.media != null){
      this.getImageThumbnail();
    }
  },
}
</script>