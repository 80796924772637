<template>
  <div>
    <SingleSlydepost
      class="border border-light-gray"
      :meta-rating="metaRating"
      :category="category"
      :subcategory="subcategory"
      :title="title"
      :user-id="userId"
      :profile-picture-url="profilePictureUrl"
      :link="link"
      :created-at="createdAt"
      :user-first-name="firstName"
      :aspects="aspects"
      :description="description"
      :address="address"
      :likes="likes"
      :id="id"
      :comments="comments"
      :display-comments="true"
      :is-preview="false"
      :media="images"
      :user-last-name="lastName"
    />
  </div>
</template>

<script>
import SingleSlydepost from '@/components/ViewSlydepost/Wrappers/SingleSlydepost.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SlydepostView',
  components: {
    SingleSlydepost,
  },
  data() {
    return {
      postId: '',
    };
  },
  computed: {
    ...mapGetters({
      metaRating: 'viewSlydepostStoreModule/getMetaRating',
      category: 'viewSlydepostStoreModule/getCategory',
      subcategory: 'viewSlydepostStoreModule/getSubcategory',
      title: 'viewSlydepostStoreModule/getTitle',
      userId: 'viewSlydepostStoreModule/getUserId',
      images: 'viewSlydepostStoreModule/getImages',
      profilePictureUrl: 'viewSlydepostStoreModule/getProfilePictureUrl',
      link: 'viewSlydepostStoreModule/getLink',
      createdAt: 'viewSlydepostStoreModule/getCreatedAt',
      firstName: 'viewSlydepostStoreModule/getUserFirstName',
      lastName: 'viewSlydepostStoreModule/getUserLastName',
      aspects: 'viewSlydepostStoreModule/getAspects',
      description: 'viewSlydepostStoreModule/getDescription',
      address: 'viewSlydepostStoreModule/getAddress',
      likes: 'viewSlydepostStoreModule/getLikes',
      id: 'viewSlydepostStoreModule/getId',
      comments: 'viewSlydepostStoreModule/getComments',
    }),
  },
  mounted() {
    this.postId = this.$route.params.postId;
    if (this.postId != null) {
      this.$store.dispatch('viewSlydepostStoreModule/getSlydepost', this.postId);
    } else {
      const commentId = this.$route.params.commentId;
      this.$store.dispatch('viewSlydepostStoreModule/getSlydepostByCommentId', commentId);
    }
  },
  unmounted() {
    this.$store.commit('viewSlydepostStoreModule/clearSlydepost');
  },
};
</script>
