import client from '@/axios/client';
import { uuid } from 'vue-uuid';

const relationshipStoreModule = {
  namespaced: true,
  state: {
    // logged in user
    pendingRequests: [],
    pendingRequestsLength: 0,
    isLoadingPendingRequests: false,
    followers: [],
    followersLength: 0,
    isLoadingFollowers: false,
    following: [],
    followingLength: 0,
    isLoadingFollowing: false,
    muted: [],
    mutedLength: 0,
    isLoadingMuted: false,
    blocked: [],
    blockedLength: 0,
    isLoadingBlocked: false,
    relationshipBetweenLoggedInUserAndFriend: {},
    publicUserTotalFollowers: 0,
  },
  getters: {
    getIsLoadingPendingRequests(state) {
      return state.isLoadingPendingRequests;
    },
    getIsLoadingFollowers(state) {
      return state.isLoadingFollowers;
    },
    getIsLoadingFollowing(state) {
      return state.isLoadingFollowing;
    },
    getIsLoadingMuted(state) {
      return state.isLoadingMuted;
    },
    getIsLoadingBlocked(state) {
      return state.isLoadingBlocked;
    },
    getPublicUserTotalFollowers(state) {
      return state.publicUserTotalFollowers;
    },
    getPendingRequests(state) {
      return state.pendingRequests;
    },
    getTotalPendingRequests(state) {
      return state.pendingRequestsLength;
    },
    getFollowers(state) {
      return state.followers;
    },
    getFollowing(state) {
      return state.following;
    },
    getBlocked(state) {
      return state.blocked;
    },
    getMuted(state) {
      return state.muted;
    },
    getRelationshipBetweenLoggedInUserAndFriend(state) {
      return state.relationshipBetweenLoggedInUserAndFriend;
    },
  },
  mutations: {
    setIsLoadingPendingRequests(state, loading) {
      state.isLoadingPendingRequests = loading;
    },
    setIsLoadingFollowing(state, loading) {
      state.isLoadingFollowing = loading;
    },
    setIsLoadingFollowers(state, loading) {
      state.isLoadingFollowers = loading;
    },
    setIsLoadingMuted(state, loading) {
      state.isLoadingMuted = loading;
    },
    setIsLoadingBlocked(state, loading) {
      state.isLoadingBlocked = loading;
    },
    setPending(state, payload) {
      state.pendingRequests = payload;
    },
    setTotalPending(state, total) {
      state.pendingRequestsLength = total;
    },
    setFollowers(state, followers) {
      state.followers = followers;
    },
    setTotalFollowers(state, total) {
      state.followersLength = total;
    },
    setFollowing(state, following) {
      state.following = following;
    },
    setTotalFollowing(state, total) {
      state.followingLength = total;
    },
    setMuted(state, muted) {
      state.muted = muted;
    },
    setTotalMuted(state, total) {
      state.mutedLength = total;
    },
    setBlocked(state, blocked) {
      state.blocked = blocked;
    },
    setTotalBlocked(state, total) {
      state.blockedLength = total;
    },
    addPendingRequest(state, payload) {
      state.pendingRequests.unshift(payload);
    },
    addFollower(state, payload) {
      state.followers.unshift(payload);
    },
    addFollowing(state, payload) {
      state.following.unshift(payload);
    },
    addBlocked(state, payload) {
      state.blocked.unshift(payload);
    },
    addMuted(state, payload) {
      state.muted.unshift(payload);
    },
    moveRelationshipFromRequestToFollow(state, relationshipId) {
      for (let i = 0; i < state.pendingRequests.length; i++) {
        if (state.pendingRequests[i].id === relationshipId) {
          const relationship = state.pendingRequests[i];
          state.pendingRequests.splice(i, 1);
          state.followers.unshift(relationship);
          return;
        }
      }
    },
    moveRelationshipFromFollowingToMuted(state, friendId) {
      for (let i = 0; i < state.following.length; i++) {
        if (state.following[i].friend.id === friendId) {
          const relationship = state.following[i];
          state.following.splice(i, 1);
          state.muted.unshift(relationship);
          return;
        }
      }
    },
    moveRelationshipFromMutedToFollowing(state, friendId) {
      for (let i = 0; i < state.muted.length; i++) {
        if (state.muted[i].friend.id === friendId) {
          const relationship = state.muted[i];
          state.muted.splice(i, 1);
          state.following.unshift(relationship);
          return;
        }
      }
    },
    blockUserFromRequests(state, friendId) {
      for (let i = 0; i < state.requests.length; i++) {
        if (state.requests[i].friend.id === friendId) {
          const relationship = state.requests[i];
          state.requests.splice(i, 1);
          state.blocked.unshift(relationship);
          return;
        }
      }
    },
    blockUserFromFollowers(state, friendId) {
      for (let i = 0; i < state.followers.length; i++) {
        if (state.followers[i].friend.id === friendId) {
          const relationship = state.followers[i];
          state.followers.splice(i, 1);
          state.blocked.unshift(relationship);
          return;
        }
      }
    },
    blockUserFromFollowing(state, friendId) {
      for (let i = 0; i < state.following.length; i++) {
        if (state.following[i].friend.id === friendId) {
          const relationship = state.following[i];
          state.following.splice(i, 1);
          state.blocked.unshift(relationship);
          return;
        }
      }
    },
    blockUserFromMuted(state, friendId) {
      for (let i = 0; i < state.muted.length; i++) {
        if (state.muted[i].friend.id === friendId) {
          const relationship = state.muted[i];
          state.muted.splice(i, 1);
          state.blocked.unshift(relationship);
          return;
        }
      }
    },
    removePendingRequest(state, relationshipId) {
      for (let i = 0; i < state.pendingRequests.length; i++) {
        if (state.pendingRequests[i].id === relationshipId) {
          state.pendingRequests.splice(i, 1);
        }
      }
    },
    removeFollowedUser(state, relationshipId) {
      for (let i = 0; i < state.following.length; i++) {
        if (state.following[i].id === relationshipId) {
          state.following.splice(i, 1);
        }
      }
    },
    removeBlockedUser(state, relationshipId) {
      for (let i = 0; i < state.blocked.length; i++) {
        if (state.blocked[i].id === relationshipId) {
          state.blocked.splice(i, 1);
        }
      }
    },
    decrementTotalPendingRequests(state) {
      state.pendingRequestsLength -= 1;
    },
    setPendingRequestsLength(state, length) {
      state.pendingRequestsLength = length;
    },
    clearCommunity(state) {
      state.followers = [];
      state.following = [];
      state.pendingRequests = [];
      state.blocked = [];
      state.muted = [];
    },
    setRelationshipBetweenLoggedInUserAndFriend(state, payload) {
      state.relationshipBetweenLoggedInUserAndFriend = payload;
    },
    setPublicUserTotalFollowers(state, number) {
      state.publicUserTotalFollowers = number;
    },
  },
  actions: {
    followUser({ dispatch, commit, getters, rootGetters }, friendId) {
      const userId = rootGetters['authStoreModule/getUserId'];
      let notification = {
        friendId: userId,
        userId: friendId,
        friendProfilePictureUrl: rootGetters['getS3Url'],
        friendFirstName: rootGetters['getFirstName'],
        friendLastName: rootGetters['getLastName'],
        resized: rootGetters['getIsResized'],
      };
      const jsonBody = JSON.stringify({
        userId,
        friendId,
        notification,
      });

      client
        .post(`/relationship`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('addFollowing', response.data);
          commit('setRelationshipBetweenLoggedInUserAndFriend', response.data);
          gtag('event', 'user-followed', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
          return response.data;
        })
        .catch((error) => {
          console.error('Unable to retrieve relationship', error);
        });
    },
    unfollowUser({ dispatch, commit, getters, rootGetters }, relationshipId) {
      const userId = rootGetters['authStoreModule/getUserId'];
      client
        .delete(`/relationship/${relationshipId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('removeFollowedUser', relationshipId);
          commit('setRelationshipBetweenLoggedInUserAndFriend', {});
          gtag('event', 'user-unfollowed', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((error) => {
          console.error('Unable to delete relationship', error);
        });
    },
    muteUser({ dispatch, commit, getters, rootGetters }, friendId) {
      const status = 'MUTED';
      const userId = rootGetters['authStoreModule/getUserId'];
      const jsonBody = JSON.stringify({
        status,
        friendId,
        userId,
      });
      client
        .put(`/relationship/`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('moveRelationshipFromFollowingToMuted', friendId);
          gtag('event', 'user-muted', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((res) => {
          console.error(res);
        });
    },
    unmuteUser({ dispatch, commit, getters, rootGetters }, friendId) {
      const status = 'FOLLOWING';
      const userId = rootGetters['authStoreModule/getUserId'];
      const jsonBody = JSON.stringify({
        status,
        friendId,
        userId,
      });
      client
        .put(`/relationship`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('moveRelationshipFromMutedToFollowing', friendId);
          gtag('event', 'user-unmuted', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((res) => {
          console.error(res);
        });
    },
    blockUser({ dispatch, commit, getters, rootGetters }, friendId) {
      const status = 'BLOCKED';
      const userId = rootGetters['authStoreModule/getUserId'];
      const jsonBody = JSON.stringify({
        status,
        friendId,
        userId,
      });
      client
        .put(`/relationship/`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          gtag('event', 'user-blocked', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((res) => {
          console.error(res);
        });
    },
    unblockUser({ dispatch, commit, getters, rootGetters }, relationshipId) {
      const userId = rootGetters['authStoreModule/getUserId'];
      client
        .delete(`/relationship/${relationshipId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('removeBlockedUser', relationshipId);
          gtag('event', 'user-unblocked', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((error) => {
          console.error('Unable to delete relationship', error);
        });
    },
    async getRelationshipBetweenLoggedInUserAndFriend({ dispatch, commit, getters, rootGetters }, friendId) {
      const userId = rootGetters['authStoreModule/getUserId'];
      client
        .get(`/relationship?userId=${userId}&friendId=${friendId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('setRelationshipBetweenLoggedInUserAndFriend', response.data);
          return response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            commit('setRelationshipBetweenLoggedInUserAndFriend', null);
            return;
          }
          console.error('Unable to retrieve relationship', error);
        });
    },
    getRelationships({ dispatch, commit, getters, rootGetters }) {
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      commit('setIsLoadingPendingRequests', true);
      commit('setIsLoadingFollowing', true);
      commit('setIsLoadingFollowers', true);
      commit('setIsLoadingMuted', true);
      commit('setIsLoadingBlocked', true);
      client
        .get(`/relationship/relationships/${loggedInUserId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then((response) => {
          commit('setPending', response.data.requests);
          commit('setTotalPending', response.data.totalRequests);
          commit('setFollowers', response.data.followers);
          commit('setTotalFollowers', response.data.totalFollowers);
          commit('setFollowing', response.data.following);
          commit('setTotalFollowing', response.data.totalFollowing);
          commit('setMuted', response.data.muted);
          commit('setTotalMuted', response.data.totalMuted);
          commit('setBlocked', response.data.blocked);
          commit('setTotalBlocked', response.data.totalBlocked);
          commit('setIsLoadingPendingRequests', false);
          commit('setIsLoadingFollowing', false);
          commit('setIsLoadingFollowers', false);
          commit('setIsLoadingMuted', false);
          commit('setIsLoadingBlocked', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve pending relationships', error);
          commit('setIsLoadingPendingRequests', false);
          commit('setIsLoadingFollowing', false);
          commit('setIsLoadingFollowers', false);
          commit('setIsLoadingMuted', false);
          commit('setIsLoadingBlocked', false);
        });
    },
    getTotalNumberOfFollowers({ dispatch, commit, getters, rootGetters }, userId) {
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(`/relationship/friends/length/${userId}?status=FOLLOWING`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            commit('setPublicUserTotalFollowers', response.data.length);
          }
        })
        .catch((error) => {
          console.error('Unable to retrieve total followers', error);
        });
    },
    loadMorePendingRequests({ dispatch, commit, getters, rootGetters }) {
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      if (getters.getIsLoadingPendingRequests === true) {
        return;
      }
      commit('setIsLoadingPendingRequests', true);
      client
        .get(
          `/relationship/friend/${loggedInUserId}?status=REQUESTED&verbose=true&startingIndex=${getters.getPendingRequests.length}`,
          {
            withCredentials: true,
            headers: {
              'request-id': uuid.v1(),
              'User-Id': loggedInUserId,
            },
          }
        )
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            commit('addPendingRequest', response.data[i]);
          }
          commit('setIsLoadingPendingRequests', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve pending relationships', error);
          commit('setIsLoadingPendingRequests', false);
        });
    },
    loadMoreFollowers({ dispatch, commit, getters, rootGetters }) {
      if (getters.getIsLoadingFollowers === true) {
        return;
      }
      commit('setIsLoadingFollowers', true);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(`/relationship/friend/${loggedInUserId}?status=FOLLOWING&startingIndex=${getters.getFollowers.length}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            commit('addFollower', response.data[i]);
          }
          commit('setIsLoadingFollowers', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve followers', error);
          commit('setIsLoadingFollowers', false);
        });
    },
    loadMoreFollowing({ dispatch, commit, getters, rootGetters }) {
      if (getters.getIsLoadingFollowing === true) {
        return;
      }
      commit('setIsLoadingFollowing', true);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(
          `/relationship/user/${loggedInUserId}?status=FOLLOWING&verbose=true&startingIndex=${getters.getFollowing.length}`,
          {
            withCredentials: true,
            headers: {
              'request-id': uuid.v1(),
              'User-Id': loggedInUserId,
            },
          }
        )
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            commit('addFollowing', response.data[i]);
          }
          commit('setIsLoadingFollowing', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve following', error);
          commit('setIsLoadingFollowing', false);
        });
    },
    loadMoreBlocked({ dispatch, commit, getters, rootGetters }) {
      if (getters.getIsLoadingBlocked === true) {
        return;
      }
      commit('setIsLoadingBlocked', true);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(
          `/relationship/user/${loggedInUserId}?status=BLOCKED&verbose=true&startingIndex=${getters.getBlocked.length}`,
          {
            withCredentials: true,
            headers: {
              'request-id': uuid.v1(),
              'User-Id': loggedInUserId,
            },
          }
        )
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            commit('addBlocked', response.data[i]);
          }
          commit('setIsLoadingBlocked', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve blocked users', error);
          commit('setIsLoadingBlocked', false);
        });
    },
    loadMoreMuted({ dispatch, commit, getters, rootGetters }) {
      if (getters.getIsLoadingMuted === true) {
        return;
      }
      commit('setIsLoadingMuted', true);
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(
          `/relationship/user/${loggedInUserId}?status=MUTED&verbose=true&startingIndex=${getters.getMuted.length}`,
          {
            withCredentials: true,
            headers: {
              'request-id': uuid.v1(),
              'User-Id': loggedInUserId,
            },
          }
        )
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            commit('addMuted', response.data[i]);
          }
          commit('setIsLoadingMuted', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve muted users', error);
          commit('setIsLoadingMuted', false);
        });
    },
    acceptFriendRequest({ dispatch, commit, getters, rootGetters }, payload) {
      const userId = payload.userId;
      const friendId = payload.friendId;
      const relationshipId = payload.relationshipId;
      const jsonBody = JSON.stringify({
        userId,
        friendId,
      });
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .put(`/relationship/accept`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': loggedInUserId,
          },
        })
        .then((response) => {
          commit('moveRelationshipFromRequestToFollow', relationshipId);
          commit('decrementTotalPendingRequests');
          gtag('event', 'user-follow-request-accepted', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((res) => {
          console.error(res);
        });
    },
    ignoreFriendRequest({ dispatch, commit, getters, rootGetters }, relationshipId) {
      const userId = rootGetters['authStoreModule/getUserId'];
      client
        .delete(`/relationship/${relationshipId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          commit('removePendingRequest', relationshipId);
          commit('decrementTotalPendingRequests');
          gtag('event', 'user-follow-request-ignored', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((res) => {
          console.error(res);
        });
    },
  },
};

// export the entire module
export { relationshipStoreModule };
