import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const editListStoreModule = {
  namespaced: true,
  state: {
    listId: '',
    title: '',
    description: '',
    status: '',
    file: null,
    existingImageUrl: '',
  },
  getters: {
    getListId(state) {
      return state.listId;
    },
    getTitle(state) {
      return state.title;
    },
    getDescription(state) {
      return state.description;
    },
    getStatus(state) {
      return state.status;
    },
    getFile(state) {
      return state.file;
    },
    getExistingImageUrl(state) {
      return state.existingImageUrl;
    },
  },
  mutations: {
    setListId(state, id) {
      state.listId = id;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setFile(state, file) {
      state.file = file;
    },
    setExistingImageUrl(state, image) {
      state.existingImageUrl = image;
    },
    clear(state) {
      state.listId = '';
      state.title = '';
      state.description = '';
      state.file = null;
      state.status = '';
      state.existingImageUrl = '';
    },
  },
  actions: {
    async updateListByLoggedInUser({ dispatch, commit, getters, rootGetters }) {
      const userId = rootGetters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      const listId = getters.getListId;
      const title = getters.getTitle;
      const description = getters.getDescription;
      const status = getters.getStatus;
      const userFirstName = rootGetters['authStoreModule/getFirstName'];
      const userLastName = rootGetters['authStoreModule/getLastName'];
      const userProfilePictureUrl = rootGetters['authStoreModule/getProfilePhoto'];
      let url = undefined;
      if (getters.getFile) {
        url = getters.getFile.name;
      }
      const jsonBody = JSON.stringify({
        listId,
        url,
        title,
        description,
        userId,
        userFirstName,
        userLastName,
        userProfilePictureUrl,
        status,
      });
      commit('singleListStoreModule/setTitle', title, { root: true });
      commit('singleListStoreModule/setDescription', description, {
        root: true,
      });
      if (getters.getFile != null) {
        commit('singleListStoreModule/setPresignedUrl', URL.createObjectURL(getters.getFile), { root: true });
      }
      client
        .put(`/post/list`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then(async (response) => {
          const { data } = response;
          if (data.presignedUrlForUpload) {
            await client
              .put(data.presignedUrlForUpload, getters.getFile, {
                headers: {
                  'Content-Type': 'image/jpeg',
                },
              })
              .then((response) => {})
              .catch((response) => {
                console.error(`could not upload`, response);
              });
          }
          gtag('event', 'list-edited', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((error) => {
          console.error('Unable to create a new list', error);
        });
    },
  },
};

// export the entire module
export { editListStoreModule };
