<template>
  <div class="w-full flex flex-col gap-2 px-4 py-4">
    <div class="flex items-center gap-4">
      <img class="hidden md:inline w-12 h-12 rounded-2xl" :src="profilePictureUrl" />
      <div class="flex flex-row justify-between items-center w-full">
        <p class="text-lg text-blue">Rate your experience</p>
        <font-awesome-icon @click="$emit('exit')" class="cursor-pointer text-sm" icon="fa-solid fa-x" />
      </div>
    </div>
    <div class="flex flex-col">
      <hr class="solid mb-3 md:mb-6" />
      <textarea
        v-model="this.description"
        class="h-auto md:h-36 max-h-40 min-h-20 mb-3 md:mb-6"
        placeholder="Tell us what you think (Optional)"
      ></textarea>
      <div class="text-blue font-base text-start uppercase" v-for="aspect in this.aspects" :key="aspect">
        <RatingSlider
          class="pb-3 md:pb-6"
          v-if="aspect.selected === true"
          :name="aspect.name"
          :rating="aspect.rating"
          :description="aspect.description"
          ref="aspectRatings"
        />
      </div>
      <div v-if='isUploadingDraft'>
        <div v-for='file in files' :key='file.order' class='pb-4'>
          <ProgressBar :progress='getDraftUploadProgress(file.order)'/>
        </div>
      </div>
      <div class="flex pb-2 justify-between items-center">
        <div>
          <font-awesome-icon
            @click="previousModal"
            :color="'#015D79'"
            class="cursor-pointer text-3xl"
            :icon="['fas', 'arrow-left']"
          />
        </div>
        <div class="flex flex-row gap-2 items-center">
          <LoaderButton
            :icon-left-side="false"
            :show-error-icon="false"
            :status="saveDraftStatus"
            :waiting-text="''"
            :in-progress-text="'Saving'"
            :done-text="'Saved!'"
            :error-text="'Try Again?'"
            :waiting-icon="'save'"
            @click="saveDraft"
          />
          <font-awesome-icon
            @click="nextModal"
            :color="'#30C3E3'"
            class="cursor-pointer text-3xl"
            :icon="['fas', 'arrow-right']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RatingSlider from '@/components/CreateSlydepost/RatingSlider.vue';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore';
import LoaderButton from '@/components/Buttons/LoaderButton.vue';
import { mapGetters } from 'vuex';
import ProgressBar from '@/components/Utility/ProgressBar.vue';

export default {
  name: 'EditDraftRatings',
  emits: ['back', 'next'],
  setup() {
    const slydepostDraftStore = useSlydepostDraftStore();
    return { slydepostDraftStore };
  },
  components: {
    RatingSlider,
    LoaderButton,
    ProgressBar
  },
  computed: {
    ...mapGetters({
      isUploadingDraft: 'editPostDraftStoreModule/getIsUploadingFilesToS3',
      files: 'editPostDraftStoreModule/getFiles',
    }),
    saveDraftStatus: {
      get() {
        return this.$store.getters['editPostDraftStoreModule/getSaveDraftStatus'];
      },
      set(status) {
        this.$store.commit('editPostDraftStoreModule/setSaveDraftStatus', status);
      },
    },
    profilePictureUrl: {
      get() {
        return this.$store.getters.getProfilePictureUrl;
      },
    },
    aspects: {
      get() {
        return this.$store.getters['editPostDraftStoreModule/getAspects'];
      },
      set() {
        this.$store.commit('editPostDraftStoreModule/setAspects');
      },
    },
    description: {
      get() {
        return this.$store.getters['editPostDraftStoreModule/getDescription'];
      },
      set(description) {
        this.$store.commit('editPostDraftStoreModule/setDescription', description);
      },
    },
  },
  methods: {
    async saveDraft() {
      this.saveAspects();
      this.saveDataIntoDraft();
      this.$store.dispatch('editPostDraftStoreModule/saveDraft')
    },
    previousModal() {
      // store the new rated aspects
      this.saveAspects();
      this.saveDataIntoDraft();
      this.$emit('back');
    },
    nextModal() {
      // store the new rated aspects
      this.saveAspects();
      this.saveDataIntoDraft();
      this.$emit('next');
    },
    saveAspects(){
      this.$refs.aspectRatings.forEach((aspect) => {
        aspect.saveAspectData();
      });
    },
    saveDataIntoDraft(){
      this.$store.commit('editPostDraftStoreModule/setDescription', this.description)
      this.$store.commit('editPostDraftStoreModule/setAspects', this.$store.getters['editPostDraftStoreModule/getSelectedAspects'])
      this.$store.commit('editPostDraftStoreModule/setSharedWith', this.sharedWith)
    },
    getDraftUploadProgress(order){
      let progress = this.$store.getters['editPostDraftStoreModule/getUploadProgress']
      return progress[order]
    },
  },
};
</script>
