<template>
  <div>
    <div
      @click="$refs.file.click()"
      class="h-12 w-48 bg-gradient-to-r from-ice to-chambray rounded-xl flex cursor-pointer border border-blue"
    >
      <div class="w-12 h-full rounded-xl border border-blue bg-transparent flex items-center justify-center">
        <img class="h-7 bg-transparent" src="@/assets/media-icon.png" alt="" />
      </div>
      <input type="file" ref="file" class="hidden" @change="selectedFile" multiple />
      <div class="h-full flex bg-transparent items-center justify-center flex-2">
        <p class="bg-transparent">{{ this.text }}</p>
      </div>
    </div>
    <div class="pl-2 pt-2 flex whitespace-nowrap relative overflow-x-scroll overflow-y-hidden gap-2">
      <ImageLoadingSpinner :is-loading="isLoadingImages" :total-images="imagesSelectedCount" />
      <draggable
        v-if="this.files != null && this.files.length > 0"
        class="dragArea list-group w-full flex flex-row gap-3"
        :list="this.files"
      >
        <div v-for="(file, index) in this.files" :key="file">
          <div class="relative">
            <div
              class="absolute top-0 -left-1 z-20 bg-chambray rounded-full w-5 h-5 p-0 text-xs border border-dark-blue text-center text-dark-blue align-middle"
            >
              <div class="h-full flex justify-center items-center">
                <p>{{ index + 1 }}</p>
              </div>
            </div>
            <ImagePreview v-if='file.type === `IMAGE`' class="list-group-item" :file="this.getLocalUrlFromFile(file.file)" draggable="true" />
            <VideoPreview v-if='file.type === `VIDEO`' :file='file'/>
            <div
              class="absolute top-0 -right-1 h-5 w-5 rounded-full border border-dark-blue cursor-pointer bg-chambray z-20"
            >
              <div class="h-full flex justify-center items-center">
                <font-awesome-icon
                  @click="this.removeImage(file)"
                  class="text-xs text-dark-blue"
                  :icon="['fas', 'xmark']"
                />
              </div>
            </div>
          </div>
        </div>
      </draggable>
      <draggable
        v-if="existingMedia != null && existingMedia.length > 0"
        class="dragArea list-group w-full flex flex-row gap-3"
        :list="existingMedia"
      >
        <div v-for="(file, index) in existingMedia" :key="file">
          <div class="relative">
            <div
              class="absolute top-0 -left-1 z-20 bg-chambray rounded-full w-5 h-5 p-0 text-xs border border-dark-blue text-center text-dark-blue align-middle"
            >
              <div class="h-full flex justify-center items-center">
                <p>{{ index + 1 }}</p>
              </div>
            </div>
            <S3ImagePreview v-if='file.type === `IMAGE`' :key="file" :file="file.presignedUrl" />
            <S3ImagePreview v-if='file.type === `VIDEO`' :key="file" :file="file.videoThumbnail" />
            <div
              class="absolute top-0 -right-1 h-5 w-5 rounded-full border border-dark-blue cursor-pointer bg-chambray z-20"
            >
              <div class="h-full flex justify-center items-center">
                <font-awesome-icon
                  @click="this.removeS3Image(file)"
                  class="text-xs text-dark-blue"
                  :icon="['fas', 'xmark']"
                />
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <div v-if="showError">
      <p class="text-red-500">{{ errorMessage }}</p>
    </div>
    <canvas id="canvas" style="display: none"></canvas>
  </div>
</template>

<script>
import ImagePreview from '@/components/MediaUpload/ImagePreview.vue';
import VideoPreview from '@/components/MediaUpload/VideoPreview.vue';
import ImageLoadingSpinner from '@/components/Utility/ImageLoadingSpinner.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import heic2any from 'heic2any';
import S3ImagePreview from '@/components/MediaUpload/S3ImagePreview.vue';

export default {
  name: 'EditDraftUploadMedia',
  props: ['text'],
  data() {
    return {
      max: 5,
      showError: false,
      errorMessage: '',
      imagesSelectedCount: 0,
      isLoadingImages: false,
      existingMedia: [],
    };
  },
  computed: {
    files: {
      get() {
        return this.$store.getters['editPostDraftStoreModule/getFiles'];
      },
      set(files) {
        this.$store.commit('editPostDraftStoreModule/setFiles', files);
      },
    },
    existingS3Images: {
      get() {
        return this.$store.getters['editPostDraftStoreModule/getExistingS3Images'];
      },
      set(files) {
        this.$store.commit('editPostDraftStoreModule/setExistingS3Images', files);
      },
    },
  },
  components: {
    ImagePreview,
    VideoPreview,
    draggable: VueDraggableNext,
    ImageLoadingSpinner,
    S3ImagePreview,
  },
  methods: {
    removeImage(file) {
      this.files = this.files.filter((item) => item !== file);
    },
    removeS3Image(file) {
      this.existingS3Images = this.existingS3Images.filter((item) => item !== file);
    },
    getLocalUrlFromFile(file) {
      return URL.createObjectURL(file);
    },
    async selectedFile(event) {
      const MAX_FILE_SIZE = 200 * 1024 * 1024;
      this.showError = false;
      this.isLoadingImages = true;

      const tempFiles = event.target.files;
      if (tempFiles.length + this.files.length > this.max) {
        this.errorMessage = 'Select only up to 5 photos';
        this.showError = true;
        return;
      }
      if (tempFiles) {
        this.imagesSelectedCount = tempFiles.length;
        const files_count = tempFiles.length;
        for (let i = 0; i < files_count; i++) {
          let fileType = this.getFileType(tempFiles[i]);
          if (fileType !== 'IMAGE' && fileType !== 'HEIC' && fileType !== 'VIDEO') {
            this.errorMessage = 'Select only images';
            this.showError = true;
            return;
          }
          try {
            let blob;
            if (fileType === 'HEIC') {
              await heic2any({
                blob: tempFiles[i],
                toType: 'image/jpeg',
                quality: 0.7, // Adjust quality as needed
              }).then(function (convertedBlob) {
                blob = convertedBlob;
                fileType = 'IMAGE'
              });
            } else {
              blob = tempFiles[i];
            }

            if (blob.size > MAX_FILE_SIZE) {
              this.errorMessage = 'A file size exceeds 200MB limit';
              this.showError = true;
              continue;
            }

            if(fileType === 'VIDEO'){
              const isDurationValid = await this.checkVideoDuration(blob);
              if (!isDurationValid) {
                this.errorMessage = 'Video duration exceeds 60 seconds limit';
                this.showError = true;
                continue;
              }
            }

            console.log("blob set");
            if(fileType === 'IMAGE'){
              blob = await this.resizeImage(blob, 1080, 1080);
              console.log(`blob resized`)
            }
            let uuid = await this.uuidv4();
            const uuidFile = new File([blob], uuid);
            console.log(`uuid file created`)
            this.files.push({
              file: uuidFile,
              order: i,
              type: fileType
            });
            console.log(`uuid file store in files`)
            this.imagesSelectedCount -= 1;
          } catch (error) {
            console.error('Error resizing image:', error);
          }
        }
      }
      this.isLoadingImages = false;
      this.existingS3Images = [];
    },
    getFileType(file) {
      if (file.type.match('image/heic')) {
        return 'HEIC';
      }
      if (file.type.match('image.*')) {
        return 'IMAGE';
      }
      if (file.type.match('video.*')) {
        return 'VIDEO';
      }
    },
    async uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },
    async resizeImage(file, maxWidth, maxHeight) {
      return new Promise((resolve, reject) => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          let newWidth, newHeight;
          // if image size is smaller than max, don't change anything
          if (image.width < maxWidth && image.height < maxHeight) {
            newWidth = image.width;
            newHeight = image.height;
          }
          // Calculate new dimensions while maintaining the aspect ratio
          else if (image.width > image.height) {
            newWidth = maxWidth;
            newHeight = (image.height * maxWidth) / image.width;
          } else {
            newHeight = maxHeight;
            newWidth = (image.width * maxHeight) / image.height;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          // Draw the image on the canvas with the new dimensions
          ctx.drawImage(image, 0, 0, newWidth, newHeight);
          // Convert the canvas content to a Blob
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            file.type,
            1.0
          );
        };

        image.src = URL.createObjectURL(file);
      });
    },
    async checkVideoDuration(file) {
      return new Promise((resolve, reject) => {
        const MAX_DURATION = 60; // Maximum duration in seconds
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          if (video.duration > MAX_DURATION) {
            resolve(false); // Duration exceeds the limit
          } else {
            resolve(true); // Duration is within the limit
          }
        };

        video.onerror = function () {
          window.URL.revokeObjectURL(video.src);
          console.error('Failed to load video metadata');
          reject(new Error('Failed to load video metadata'));
        };

        video.src = URL.createObjectURL(file);
      });
    },
  },
  mounted(){
    this.existingMedia = this.existingS3Images.sort((a, b) => (a.order > b.order ? 1 : -1));
  },
  unmounted() {
    // if files are being uploaded, take the order they're in
    if (this.files.length > 0) {
      let orderedFiles = [];
      for (let i = 0; i < this.files.length; i++) {
        orderedFiles.push({
          file: this.files[i].file,
          order: i,
          type: this.files[i].type,
        });
      }
      this.files = orderedFiles;
    } else if (this.existingMedia.length > 0) {
      this.existingS3Images = this.this.existingMedia;
      for (let i = 0; i < this.existingS3Images.length; i++) {
        this.existingS3Images[i].order = i;
      }
      let sorted = this.existingS3Images;
      this.existingS3Images = sorted.sort((a, b) => (a.order > b.order ? 1 : -1));
    }
  },
};
</script>
