<template>
  <div class="px-4 pb-2 lg:px-0 pt-4 lg:pt-0">
    <p class="text-2xl pb-4 text-dark-gray">Drafts</p>
    <!-- Tabs -->
    <div class="flex flex-row gap-5 pb-2 text-dark-gray">
      <p @click="this.activeTab = 'slydeposts'" :style="getSlydepostsStyling" class="cursor-pointer">
        Slydepost Drafts
      </p>
      <p @click="this.activeTab = 'drafts'" :style="getListStyling" class="cursor-pointer">List Drafts</p>
    </div>
    <!-- Tab Contents -->
    <div class="pt-4">
      <div v-show="activeTab === 'slydeposts'">
        <div class="bg-white rounded-lg shadow overflow-hidden">
          <DraftItem
            v-for="draft in slydepostDraftStore.slydepostDrafts"
            :key="draft.id"
            :draft="draft"
            @deleteDraft="deleteDraft(draft.id, draft.type)"
            @continueCreation="continuePostCreation(draft)"
          />
        </div>
      </div>
      <div v-show="activeTab === 'drafts'">
        <div class="bg-white rounded-lg shadow overflow-hidden">
          <DraftItem
            v-for="draft in slydepostDraftStore.listDrafts"
            :key="draft.id"
            :draft="draft"
            @deleteDraft="deleteListDraft(draft.id, draft.type)"
            @continueCreation="continueListCreation(draft)"
          />
        </div>
        <AddListModal ref="addListModal" />
      </div>
    </div>
  </div>
</template>

<script>
import DraftItem from '@/components/Drafts/DraftListItem.vue';
import { useSlydepostDraftStore } from '@/piniaStores/DraftStore.js';
import AddListModal from '@/components/Modals/AddListModal.vue';
import { client } from '@/axios/client.js';
export default {
  name: 'Drafts',
  setup() {
    const slydepostDraftStore = useSlydepostDraftStore();
    return { slydepostDraftStore };
  },
  props: {
    drafts: {
      type: Array,
    },
  },
  components: {
    DraftItem,
    AddListModal,
  },
  data() {
    return {
      activeTab: 'slydeposts',
      postDrafts: this.generateDrafts(100, 'Slydepost'),
      listDrafts: this.generateDrafts(100, 'List'),
      //TODO: Fetch data for drafts from server when available
    };
  },
  computed: {
    getSlydepostsStyling() {
      return this.activeTab === 'slydeposts'
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
    getListStyling() {
      return this.activeTab === 'drafts'
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
  },
  methods: {
    generateDrafts(count, type) {
      let drafts = [];
      let date = new Date();
      for (let i = 0; i < count; i++) {
        date.setDate(date.getDate() + i);
        let formattedDate = date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        drafts.push({
          id: i + 1,
          title: type + `Draft Title ${i + 1}`,
          date: formattedDate,
          type: type,
        });
      }
      return drafts;
    },
    deleteDraft(id, type) {
      const userId = this.$store.getters['authStoreModule/getUserId'];
      this.slydepostDraftStore.deleteDraft(id, userId);
    },
    deleteListDraft(id, type) {
      const userId = this.$store.getters['authStoreModule/getUserId'];
      this.slydepostDraftStore.deleteListDraft(id, userId);
    },
    continuePostCreation(draft) {
      if (draft.aspects !== undefined) {
        draft.aspects.forEach((aspect) => {
          if (!aspect.selected) {
            aspect.selected = true;
          }
        });
      } else {
        draft.aspects = [];
      }

      this.$store.commit('editPostDraftStoreModule/setDraftId', draft.id)
      this.$store.commit('editPostDraftStoreModule/setTitle', draft.title)
      this.$store.commit('editPostDraftStoreModule/setAddress', draft.address)
      this.$store.commit('editPostDraftStoreModule/setLink', draft.link)
      this.$store.commit('editPostDraftStoreModule/setCategory', draft.category)
      this.$store.commit('editPostDraftStoreModule/setSubcategory', draft.subcategory)
      this.$store.commit('editPostDraftStoreModule/setExistingS3Images', draft.media || [])
      this.$store.commit('editPostDraftStoreModule/setAspects', draft.aspects.filter((aspect) => aspect.selected === true))
      this.$store.commit('editPostDraftStoreModule/setSuggestedAspects', draft.suggestedAspects || [])
      this.$store.commit('editPostDraftStoreModule/setDescription', draft.description)
      this.$store.commit('editPostDraftStoreModule/setSharedWith', draft.sharedWith)
      this.$store.commit('editPostDraftStoreModule/setCategoryDropdownSearchFilter', draft.subcategory || draft.category)
      this.$store.commit('editPostDraftStoreModule/setCategoryDropdownSelectedOptionIndex', draft.categoryDropdownSelectedOptionIndex)

      //navigate to the edit post page
      this.$router.push('/draft/edit');
    },
    continueListCreation(draft) {
      this.$store.commit('listsStoreModule/setDraft', draft);
      this.$refs.addListModal.openModal();
    },
  },
  async mounted() {
    const userId = this.$store.getters['authStoreModule/getUserId'];
    await this.slydepostDraftStore.fetchDrafts(userId);
  },
  unmounted() {
    this.slydepostDraftStore.$reset();
  },
};
</script>
