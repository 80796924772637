<template>
  <div class="lg:hidden flex flex-col w-screen bg-dark-blue px-4 py-4">
    <p class="text-xl text-white pb-2">Trending Lists</p>
    <div id="trending-lists" class="z-20 overflow-scroll">
      <div class="flex flex-row gap-4 min-w-0">
        <div v-for="list in lists" :key="list.id">
          <router-link :to="{ name: 'SingleList', params: { listId: list.listId } }" class="w-full">
            <div class="flex flex-col">
              <div class="m-auto w-20 h-20">
                <img
                  class="w-full h-full rounded-xl bg-dark-blue bg-center object-cover object-center"
                  :src="list.presignedUrl"
                />
              </div>
              <div class="text-start text-[12px] font-[400] text-white pb-1">
                <p class="leading-[15px] mt-2 line-clamp-2 w-20">
                  {{ list.title }}
                </p>
                <p class="text-moonstone">
                  {{ list.totalFollows }} Follow{{ list.totalFollows !== 1 ? 's' : '' }}&nbsp;
                </p>
                <p class="text-moonstone">{{ list.totalPosts }} Post{{ list.totalPosts !== 1 ? 's' : '' }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <p @click="goToTrendingLists" class="block text-md font-bold text-white pt-2">View Trending Lists</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrendingLists',
  computed: {
    ...mapGetters({
      lists: 'trendingListStoreModule/getViewableLists',
      isLoading: 'trendingListStoreModule/getIsLoading',
    }),
  },
  methods: {
    goToTrendingLists() {
      this.$store.commit('trendingStoreModule/setCurrentView', 'lists');
      this.$router.push({ name: 'Trending' });
    },
  },
};
</script>

<style scoped>
#trending-lists::-webkit-scrollbar {
  display: none;
}

#trending-lists {
  scrollbar-width: none;
}
</style>
