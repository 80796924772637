<template>
  <div class='loading px-4 py-4'>
    <div id='title' class='w-1/3 description'/>
    <div class='flex flex-row gap-4'>
      <div v-for='item in list' :key='item' class='flex flex-col gap-1'>

          <div id='image' class='description h-20 w-20 rounded-xl'/>
          <div id='list' class='description h-2'/>
          <div id='follows' class='description h-2'/>
          <div id='posts' class='description h-2'/>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrendingNewsfeedSkeleton',
  data(){
    return {
      list: [1,2,3]
    }
  }
}
</script>

<style scoped>
.loading .image,
.loading h4,
.loading .description {
  background-color: rgb(189 189 189 / var(--tw-bg-opacity));
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) rgb(189 189 189 / var(--tw-bg-opacity));
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
</style>