<template>
  <div>
    <Slydepost
      :id="id"
      :title="title"
      :description="description"
      :adjectives="aspects"
      :userId="userId"
      :createdAt="createdAt"
      :userFirstName="userFirstName"
      :userLastName="userLastName"
      :category="category"
      :subcategory="subcategory"
      :comments="comments"
      :address="address"
      :link="link"
      :metaRating="metaRating"
      :displayComments="displayComments"
      :media="media"
      :likes="likes"
      :profile-picture-url="profilePictureUrl"
      :files='files'
      @deleteComment="deleteComment"
      @createComment="createComment"
      @likeComment="likeComment"
      @deleteCommentLike="deleteCommentLike"
      @replyToComment="replyToComment"
      @deleteReply="deleteReply"
      @replyToReply="replyToReply"
      @likeReply="likeReply"
      @deleteLikeReply="deleteLikeReply"
      @likeSlydepost="likeSlydepost"
      @deleteLikeSlydepost="deleteLikeSlydepost"
    />
  </div>
</template>

<script>
import Slydepost from '@/components/ViewSlydepost/Slydepost.vue';

export default {
  name: 'SlydepostCreatedByUser',
  components: {
    Slydepost,
  },
  props: {
    id: String,
    title: String,
    description: String,
    aspects: Array,
    userId: String,
    createdAt: String,
    userFirstName: String,
    userLastName: String,
    category: String,
    subcategory: String,
    comments: Array,
    images: Array,
    address: String,
    link: String,
    metaRating: Number,
    displayComments: Boolean,
    media: Array,
    likes: Array,
    profilePictureUrl: String,
    files: Array,
  },
  methods: {
    deleteLikeSlydepost(payload) {
      this.$store.commit('userStoreModule/removeLikeFromSlydepostFromUsersPost', payload);
    },
    likeSlydepost(payload) {
      this.$store.commit('userStoreModule/likeSlydepostFromUsersPost', payload);
    },
    deleteLikeReply(payload) {
      this.$store.commit('userStoreModule/removeLikeReplyFromUsersPost', payload);
    },
    likeReply(payload) {
      this.$store.commit('userStoreModule/likeReplyFromUsersPost', payload);
    },
    replyToReply(payload) {
      this.$store.commit('userStoreModule/replyToCommentFromUsersPost', payload);
    },
    deleteReply(payload) {
      this.$store.commit('userStoreModule/removeReplyFromCommentFromUsersPost', payload);
    },
    replyToComment(payload) {
      this.$store.commit('userStoreModule/replyToCommentFromUsersPost', payload);
    },
    deleteCommentLike(payload) {
      this.$store.commit('userStoreModule/removeLikeCommentFromUsersPost', payload);
    },
    likeComment(payload) {
      this.$store.commit('userStoreModule/likeCommentFromUsersPost', payload);
    },
    deleteComment(payload) {
      this.$store.commit('userStoreModule/removeCommentFromUsersPost', payload);
    },
    createComment(payload) {
      this.$store.commit('userStoreModule/addCommentToUsersPost', payload);
    },
  },
};
</script>
