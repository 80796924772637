<template>
  <div v-if="this.display === true" class="flex flex-col gap-2">
    <SlydepostCommentsDisplay
      v-if="this.comments != null && this.comments.length > 0"
      :display-more-comments="this.displayMoreComments"
      :comments-list="this.comments"
      :post-id="this.postId"
      :post-owner-id="this.postOwnerId"
      @deleteComment="deleteComment"
      @likeComment="likeComment"
      @deleteCommentLike="deleteCommentLike"
      @replyToComment="replyToComment"
      @deleteReply="deleteReply"
      @replyToReply="replyToReply"
      @likeReply="likeReply"
      @deleteLikeReply="deleteLikeReply"
    />
    <div class="relative flex items-center">
      <textarea
        v-model="this.newComment"
        type="text"
        class="h-12 w-full rounded-full pl-6 border-solid border-light-gray pr-14"
        :placeholder="displayCommentPlaceholderText"
        @keydown.enter="createComment()"
      />
      <p
        v-if="newComment.length > 0"
        @click="this.createComment"
        class="absolute bottom-3 right-4 text-blue font-bold cursor-pointer"
      >
        Post
      </p>
    </div>
    <div
      @click="this.displayMoreComments = !this.displayMoreComments"
      v-if="this.comments != null && this.comments.length > MAX_NUM_COMMENTS()"
    >
      <div
        v-if="this.displayMoreComments === false"
        class="w-full flex gap-2 h-12 items-center uppercase justify-center bg-dark-blue"
      >
        <div class="bg-transparent text-white tracking-wider">view more comments</div>
        <font-awesome-icon class="bg-transparent text-teal" icon="angle-down" />
      </div>
      <div v-else class="w-full flex gap-2 h-12 items-center uppercase justify-center bg-dark-blue">
        <div class="bg-transparent text-white tracking-wider">view less comments</div>
        <font-awesome-icon class="bg-transparent text-teal" icon="angle-up" />
      </div>
    </div>
  </div>
</template>

<script>
import client from '@/axios/client';
import { uuid } from 'vue-uuid';
import { MAX_NUM_COMMENTS } from './constants';
import SlydepostCommentsDisplay from '@/components/ViewSlydepost/SlydepostCommentsDisplay.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SlydepostComments',
  data() {
    return {
      newComment: '',
      displayMoreComments: false,
      displayMoreReplies: false,
      isCreatingComment: false,
    };
  },
  props: ['comments', 'display', 'postId', 'postTitle', 'postOwnerId'],
  emits: [
    'deleteComment',
    'createComment',
    'likeComment',
    'deleteCommentLike',
    'replyToComment',
    'deleteReply',
    'replyToReply',
    'likeReply',
    'deleteLikeReply',
  ],
  components: { SlydepostCommentsDisplay },
  methods: {
    deleteLikeReply(payload) {
      this.$emit('deleteLikeReply', payload);
    },
    likeReply(payload) {
      this.$emit('likeReply', payload);
    },
    replyToReply(payload) {
      this.$emit('replyToReply', payload);
    },
    deleteReply(payload) {
      this.$emit('deleteReply', payload);
    },
    replyToComment(payload) {
      this.$emit('replyToComment', payload);
    },
    deleteCommentLike(payload) {
      this.$emit('deleteCommentLike', payload);
    },
    deleteComment(payload) {
      this.$emit('deleteComment', payload);
    },
    likeComment(payload) {
      this.$emit('likeComment', payload);
    },
    MAX_NUM_COMMENTS() {
      return MAX_NUM_COMMENTS;
    },
    createComment() {
      if (this.isCreatingComment === true) {
        return;
      }
      if (this.newComment.trim() === '') {
        return;
      }
      this.isCreatingComment = true;
      let notification = {
        postId: this.postId,
        friendId: this.loggedInUserId,
        postTitle: this.postTitle,
        userId: this.postOwnerId,
        friendProfilePictureUrl: this.$store.getters['getS3Url'],
        friendFirstName: this.$store.getters['getFirstName'],
        friendLastName: this.$store.getters['getLastName'],
        resized: this.$store.getters['getIsResized'],
      };

      const postId = this.postId;
      const userId = this.loggedInUserId;
      const comment = this.newComment;
      const jsonBody = JSON.stringify({
        postId,
        userId,
        comment,
        notification,
      });
      const createCommentResponse = client
        .post(`/post/comment`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          response.data.userFirstName = this.loggedInUserFirstName;
          response.data.userLastName = this.loggedInUserLastName;
          response.data.presignedUrl = this.userProfilePictureUrl;
          const payload = {
            comment: response.data,
            postId: this.postId,
          };
          this.$emit('createComment', payload);
          this.newComment = '';
          if (this.comments.length === MAX_NUM_COMMENTS + 1) {
            this.displayMoreComments = true;
          }
          this.isCreatingComment = false;
          gtag('event', 'slydepost-comment-created', {
            event_category: 'Slydepost',
            event_label: 'Label',
            value: 1,
          });
        })
        .catch((error) => {
          console.error(`Error comments response`, error);
          this.isCreatingComment = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      getCommentsByUser: 'userStoreModule/getPublicUserPostsCommentsByPostId',
      getCommentsByUserLiked: 'userStoreModule/getPublicLikedPostsCommentsByPostId',
      getCommentsByLoggedInUser: 'getLoggedInUserPostsCommentsByPostId',
      getCommentsByLoggedInUserLiked: 'getLoggedInUserLikedPostsCommentsByPostId',
    }),
    loggedInUserId: {
      get() {
        return this.$store.getters['authStoreModule/getUserId'];
      },
    },
    loggedInUserFirstName: {
      get() {
        return this.$store.getters['authStoreModule/getFirstName'];
      },
    },
    loggedInUserLastName: {
      get() {
        return this.$store.getters['authStoreModule/getLastName'];
      },
    },
    userProfilePictureUrl: {
      get() {
        return this.$store.getters['getProfilePictureUrl'];
      },
    },
    displayCommentPlaceholderText() {
      if (this.comments == null || this.comments.length === 0) {
        return 'Be the first to join the discussion';
      } else {
        return 'Join the discussion';
      }
    },
  },
};
</script>

<style scoped></style>
